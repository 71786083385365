import React, { useRef } from 'react';
import { Row } from 'antd';
import PropTypes from 'prop-types';

import ListSort from '../Tools';
// import { DndProvider, DragSource, DropTarget, useDrag } from 'react-dnd';
// import HTML5Backend from 'react-dnd-html5-backend';
// import update from 'immutability-helper';


const SortableList = (props) => {


  const editItem = useRef(null);

  const onClickEdit = async key => {
    await props.onEdit(key);
    editItem.current.focus();
  }

  // const actives = Object.fromEntries(Object.entries(props.options).filter(([i, val])=>props.order.includes(i)));


  const childrenToRender = Object.entries(props.options).length > 0 ? Object.entries(props.options).sort(function (a, b) {
    return props.order.indexOf(a[0]) - props.order.indexOf(b[0]);
  })
    .map(i => {
      //   const {
      //     icon, color, label, text, value
      //   } = i
      //   ;

      console.log(props.order);
      // console.log(i);

      return (

        <Row type="flex" justify="left" key={i[0]} className={`${props.className}-list`}>
          <props.ListItem {...props} i={i} onClickEdit={onClickEdit} editItem={editItem} />
          {/* <div className={`${props.className}-text`}> */}
          {/*  <Col span={4}>
            <Checkbox value={i[0]} onChange={props.onIndividualCheckChange} style={{fontSize: 30}}/>
            </Col>
            {/* <Col>
            <Paragraph editable={{ onChange: (e) => props.onEditChange(e, i[0]) }}>{i[1]}</Paragraph>
            {props.edit===i[0]?
            <Input ref={editItem}
             onPressEnter={(e) => props.onEditEnter(e, i[0])} 
             allowClear 
            defaultValue={i[1]}/>
            :<span onClick={() => onClickEdit(i[0])}>{i[1]}</span>}
            </Col>
            <Col >
            <Icon type='edit' onClick={() => onClickEdit(i[0])} style={{fontSize: 30}}/>
            </Col>
            <Col >
            <Icon type='delete' onClick={() => props.onDelete(i[0])} style={{fontSize: 30}}/>
            </Col>*/}
          {/* <Icon type="edit" onClick={()=>this.props.onEdit(value)}/>
            {/* <p>{text}</p>
          {/* </div> */}
          {/* <div className={`${props.className}-icon`}> */}
          {/* <Icon type='delete' onClick={() => props.onDelete(i[0])} /> */}
          {/* </div> */}
        </Row>

      );
    }) : <div>No item</div>;

  console.log(childrenToRender);

  return (

    <div className={`${props.className}-wrapper`}>
      <div className={props.className}>

        <ListSort
          dragClassName="list-drag-selected"
          appearAnim={{ animConfig: { marginTop: [5, 30], opacity: [1, 0] } }}
          onOrderChange={props.onOrderChange}
          initialOrder={props.order}
        >
          {childrenToRender}
        </ListSort>
      </div>

    </div>

  );
}



SortableList.propTypes = {
  className: PropTypes.string,
};

SortableList.defaultProps = {
  className: 'sortable-list',
};

export default SortableList;