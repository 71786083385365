import React from "react";

import { NavLink } from 'react-router-dom';
import { Row, Col } from 'antd';

import * as ROUTES from "../../constants/routes";
import moment from 'moment';


const Footer = () => (

    <>
        <div style={{ marginTop: 10 }}>
            <Row type="flex" gutter={2}>
                <Col span={3} offset={6}>
                    Secresilk &#169;<span>{moment().year()}</span>
                </Col>
                <Col span={2} offset={1}>
                    <NavLink
                        to={ROUTES.ABOUT}
                        activeClassName="selected">About
                    </NavLink>
                </Col>
                <Col span={3} offset={0}>
                    <NavLink
                        to={ROUTES.TERMS}
                        activeClassName="selected">Terms of Service
                    </NavLink>
                </Col>
                <Col span={3} offset={1}>
                    <NavLink
                        to={ROUTES.PRIVACY}
                        activeClassName="selected">Privacy Policy
                    </NavLink>
                </Col>
                <Col span={2} offset={0}>
                    <NavLink
                        to={ROUTES.DISCLAIMER}
                        activeClassName="selected">Disclaimer
                    </NavLink>
                </Col>
                <Col span={2} offset={0}>
                    <NavLink
                        to={ROUTES.HELP}
                        activeClassName="selected">Help
                    </NavLink>
                </Col>
            </Row>
        </div>



    </>
)

export default Footer;




