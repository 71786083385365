export const faq = [
    {
        // 'id': 1,
        'title': 'What is chiccue?',
        'body': 'Chiccue is the best product on the market right now. You can search for example, color. Chiccue is the best product on the market right now. You can search for example, color. Chiccue is the best product on the market right now. You can search for example, color. Chiccue is the best product on the market right now. You can search for example, color. Chiccue is the best product on the market right now. You can search for example, color.'
    },

    {
        // 'id': 2,
        'title': 'How do I search for a particular color?',
        'body': 'Go to the color dropdown list, and type in the color you like',
    },

];

