import React, { useState, Fragment } from "react";

import {
    Card,
    Row,
    Col,
    Typography,
    Button
    // Tag
} from "antd";

import CompTable from './compare-table';
import Subscriber from './subscribe';


const { Title } = Typography;

const { Meta } = Card;




const App = (props) => {

    const [step, setStep] = useState(0);
    const [level, setLevel] = useState(0);

    const onClickGet = (le) => {
        setLevel(le);
        setStep(1);
    }

    const step0 = <Fragment>

        <Row type="flex" justify="center" style={{ marginTop: 40 }}>
            <Title level={2}>Give your pets the gift of self-expression</Title>
        </Row>


        <Row type="flex" justify="center" style={{ marginTop: 20 }}>
            <Title level={5}>At Petspoke, we are dedicated to finding better ways to giving pets a voice. </Title>
        </Row>
        <Row type="flex" justify="center" style={{ marginTop: 10 }}>
            <Title level={5}>By being a member, you help us help them. </Title>

        </Row>

        <Row type="flex" justify="center" gutter={20} style={{ marginTop: 20 }}>
            <Col span={8} >
                <Button block onClick={() => onClickGet(1)}>Get Standard Membership</Button>
            </Col>
            <Col span={8} >
                <Button block type="primary" onClick={() => onClickGet(2)}>Get Premium Membership</Button>
            </Col>
        </Row>

    </Fragment>;

    return step === 0 ? <Fragment>
        {step0}
        < Card style={{ width: "100%", marginTop: 40 }
        }>
            <Meta
                title={<Title level={2}>Compare Levels</Title>}
                description="What level of membership suits you and your pets the best?"
            />
            <Row type="flex" justify="start" gutter={20} style={{ margin: 20 }}>
                <CompTable />
            </Row>
        </Card >
        <Row type="flex" justify="center" style={{ margin: 40 }}>
            <Button type="primary" onClick={() => props.showProfile(true)} style={{ width: "30%" }}>Proceed to View My Profile</Button>
        </Row>

    </Fragment> : <Subscriber level={level} setStep={setStep} {...props} />
}




export default App;

