import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { Layout } from "antd";

import Navigation from "../Navigation";
import LandingPage from "../Landing";
import Posts from "../Posts";
// import Home from "../Home";
import Write from "../Editor";
// import { LoginCredPage, SettingsPage } from "../Account";

import Foot from '../Footer';
import { About, Terms, Privacy, Disclaimer, Faq } from "../Docs";
import { SuccessPage, ErrorPage, CancelPage, PaymentError } from "../CheckOut";
import Notice from '../Notice';
import Help from '../Help';
import { Page403, Page404 } from "../Results";
import Profile from "../Membership";

import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";
import "../../App.less";

const { Header, Footer, Content } = Layout;

// const App = () => (
//   <Router>
//     <React.Fragment>
//       {/* <Layout>
//     <Header> */}
//       <Navigation />
//       {/* </Header> */}
//       {/* <hr/> */}
//       {/* <Content> */}
//       <div style={{ margin: "auto", maxHeight: "none" }}>
//         <Switch>
//           <Route exact path={ROUTES.LANDING} component={LandingPage} />
//           <Route path={ROUTES.POSTS} component={Posts} />
//           <Route path={ROUTES.HOME} component={Home} />
//           {/* <Route path={ROUTES.ACCOUNT} component={Account} /> */}
//           <Route exact path={ROUTES.PROFILE} component={Profile} />
//           <Route exact path={ROUTES.CRED} component={LoginCredPage} />
//           <Route exact path={ROUTES.SETTINGS} component={SettingsPage} />
//           <Route exact path={ROUTES.WRITE} component={Write} />
//           {/* <Route path="*" component={Notfound} /> */}
//           <Redirect to="/" />
//         </Switch>
//       </div>
//       {/* </Content> */}
//       {/* <hr/> */}
//       {/* <Footer transparent /> */}
//       {/* </Layout> */}
//     </React.Fragment>
//   </Router>
// );


const App = () => (
  <Router>
    <React.Fragment>
      <Layout style={{ minHeight: "100vh" }}>
        <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
          <Navigation />
        </Header>
        {/* <hr/> */}
        <Content style={{ marginTop: 64 }}>
          <Switch>
            <Route exact path={ROUTES.LANDING} component={LandingPage} />
            <Route exact path={ROUTES.HOME} component={Profile} />
            <Route exact path={ROUTES.WRITE} component={Write} />
            <Route exact path={ROUTES.POSTS} component={Posts} />
            <Route exact path={ROUTES.PROFILE} component={Profile} />
            {/* <Route exact path={ROUTES.CRED} component={LoginCredPage} />
              <Route exact path={ROUTES.SETTINGS} component={SettingsPage} /> */}
            <Route exact path={ROUTES.TERMS} component={Terms} />
            <Route exact path={ROUTES.PRIVACY} component={Privacy} />
            <Route exact path={ROUTES.DISCLAIMER} component={Disclaimer} />
            <Route exact path={ROUTES.ABOUT} component={About} />
            <Route exact path={ROUTES.FAQ} component={Faq} />
            {/* <Route exact path={ROUTES.CONTACT} component={ContactPage} /> */}
            <Route exact path={ROUTES.SUCCESS} component={SuccessPage} />
            <Route exact path={ROUTES.ERROR} component={ErrorPage} />
            <Route exact path={ROUTES.CANCELLED} component={CancelPage} />
            <Route exact path={ROUTES.HELP} component={Help} />
            <Route exact path={ROUTES.NOTICE} component={Notice} />
            <Route exact path={ROUTES.PAGE403} component={Page403} />
            <Route exact path={ROUTES.PAGE404} component={Page404} />
            <Route exact path={ROUTES.PAYMENT_ERROR} component={PaymentError} />
            {/* <Route path={ROUTES.LANDING+'?refer=:refer'} component={LandingPage} /> */}

            {/* <Route path={ROUTES.LOGINS} component={LoginCredPage} /> */}

            {/* <Route path={ROUTES.SETTINGS} component={SettingsPage} /> */}
            {/* <Route path="*" component={Notfound} /> */}
            <Redirect to={ROUTES.PAGE404} />
          </Switch>


        </Content>
        {/* <hr/> */}
        <Footer
        >
          <Foot />
        </Footer >
      </Layout>
      {/* <Affix style={{ position: 'fixed', bottom: 10, width: "100%" }}>
        <Foot />
      </Affix> */}
    </React.Fragment>
  </Router>
);

export default withAuthentication(App);
