// this is not controlled by a form; it keeps its own "value" and "onChange"; only bubbles up onSelect

import React, { useState, useRef, useEffect } from 'react';

import {
    Button,
    Input
} from 'antd';

import {
    PlusOutlined,
} from '@ant-design/icons';

import { LimitInput } from '../Designs';




const App = (props) => {

    const inputRef = useRef(null);

    const [nameinputValue, setNameinputValue] = useState('');
    // const [shortnameinputValue, setShortnameinputValue] = useState('');

    // const input2 = React.createRef();

    const onInputChange = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setNameinputValue(e.target.value);

        // else if (whichinput === 'shortname') {
        //     setShortnameinputValue(e.target.value);
        // }
    }

    const handleCreate = () => {
        if (props.clearAfterCreate) {
            setNameinputValue('');
        }
        props.handleCreate(nameinputValue);

        // setShortnameinputValue('');

    }


    useEffect(() => {

        console.log('in useEffect for focus on render, with visible:', props.visible);

        if (props.focusOnRender && props.visible) {
            console.log(inputRef.current);
            inputRef.current && inputRef.current.focus();
        }

    }, [props.visible, props.focusOnRender]);



    return (

        <Input.Group compact style={{ maxWidth: 800, margin: "auto" }}>
            <LimitInput
                ref={inputRef}
                allowClear
                style={{ width: '80%' }}
                maxLength={props.maxNameLength}
                placeholder='Write a title for your post'
                value={nameinputValue}
                onChange={onInputChange}
                onPressEnter={nameinputValue.replace(/ /g, '').length > 0 && handleCreate}
            />

            {/* <LimitInput
                ref={input2}
                allowClear
                style={{ width: '30%' }}
                maxLength={props.maxShortnameLength}
                placeholder={`Optional: place under which path`}
                value={shortnameinputValue}
                onChange={e => onInputChange(e, 'path')}
                onPressEnter={nameinputValue.replace(/ /g, '').length > 0 && handleCreate}
            /> */}
            <Button type="primary" style={{ width: "20%" }} onClick={handleCreate}
                disabled={nameinputValue.replace(/ /g, '').length < 1}>
                <PlusOutlined />Post
            </Button>
        </Input.Group>

    )
};



export default App;