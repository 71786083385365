import React, { PureComponent } from 'react';
import TextArea from 'antd/lib/input/TextArea';
// import 'antd/lib/input/style/index.css'
// import './LimitTextArea.less'

/***
 * 显示最大输入字符数
 * maxLength：200（默认）
 */
class LimitTextArea extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      len: (props.defaultValue && props.defaultValue.length) || 0,
      maxLength: 200,
      isvalid: true, // 是否显示最大字符数
    }
    // 若需要覆盖onChange时，value必填
    if (props.onChange && !props.hasOwnProperty('value')) {
      this.state.isvalid = false
      console.warn('LimitTextArea：绑定onChange时，value属性必填，否则显示最大输入字符数将失效!')
    }

    this.inputRef = React.createRef();

  }

  // 若外部定义了onChange事件，handleChange将会被覆盖
  handleChange = (e) => {
    // console.log('handleChange id striggere');
    const { sep } = this.props
    const val = e.target.value
    const arr = (val || '').split(sep);
    // const arr = (val || this.props.defaultValue || '').split(sep);
    // const arr = val ? val.split('') : [];
    // console.log('handle change arr is', arr);
    // console.log('the new length is', arr.length);
    this.setState({
      len: arr.length
      // len: val.length
    })
  }


  focus = () => {
    this.inputRef.current.focus();
  }


  render() {

    const { maxLength: defaultMax, isvalid } = this.state;
    const { maxLength, value, defaultValue, sep, ...restProps } = this.props;
    /** form组件中，value有值 */
    let len = value ? value.length : this.state.len;
    const max = maxLength > 0 ? maxLength : defaultMax;
    len = len > max ? max : len;

    // const { maxLength: defaultMax, isvalid } = this.state;
    // const { sep, maxLength, value, defaultValue, ...restProps } = this.props;
    // console.log(restProps);
    // const max = maxLength > 0 ? maxLength : defaultMax
    // /** form组件中，value有值 */
    // const arr = (value || '').split('');
    const arr = value ? value.split(sep) : (defaultValue && defaultValue.split(sep)) || [];
    // let len = value ? arr.length : this.state.len
    // len = len > max ? max : len
    // /**截取最大字符串 */
    const val = arr.slice(0, len).join(sep);
    const n = val ? len : 0
    const suffix = `${n}/${max}`



    return isvalid ? (
      <div className={'inputCharCounterBlock'} style={{ width: this.props.width }}>
        <TextArea
          ref={this.inputRef}
          onChange={e => this.handleChange(e)}
          value={value}
          maxLength={max}
          defaultValue={defaultValue}
          {...restProps}
          className={'limitTextArea-input'}
        />
        <span className={'inputCharCounter'}>{suffix}</span>
      </div>
    ) : <TextArea {...this.props} />
  }
}

LimitTextArea.defaultProps = {
  /** 分割符
   * 可以是个字符串，如：'\n'
   * 也可以是个正则表达式，如：/\n\r/
   */
  sep: ''
}

export default LimitTextArea;