import React, { Fragment } from "react";

import {
    Card,
    Row,
    Typography,
    Avatar
} from "antd";


import moment from 'moment';

const { Title } = Typography;

const { Meta } = Card;




const App = (props) => {


    return <>
        <Card style={{ width: "100%", marginTop: 20 }}>
            <Meta
                avatar={
                    <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                }
                title={<Title level={2}>{props.authUser.username}</Title>}
                description={props.authUser.email}
            />

            <Row type="flex" justify="start" gutter={0} style={{ margin: 20 }}>
                <Title level={5}>Joined Petspoke on {moment(props.authUser.created).format("MMMM D, YYYY")}</Title>
            </Row>

            {props.authUser && props.authUser.expiry && props.authUser.expiry > moment().unix() && !props.authUser.expired ?

                <Fragment>

                    <Row type="flex" justify="start" gutter={0} style={{ margin: 20 }}>
                        <Title level={5}>
                            {props.authUser.membership && `You are currently a ${props.authUser.membership === 2 && 'premium '}member of Petspoke`}
                        </Title>
                    </Row>

                    <Row type="flex" justify="start" gutter={0} style={{ margin: 20 }}>
                        <p>
                            {" "}
                            Expiry of Active Membership: {" "}
                            {moment(props.authUser.expiry).format("YYYY-MM-DD")}
                        </p>
                    </Row>

                </Fragment> :


                <Fragment>

                    <Row type="flex" justify="start" gutter={0} style={{ margin: 20 }}>
                        <Title level={5}>{'You are not a current member of Petspoke'}</Title>
                    </Row>

                </Fragment>
            }


            <Row type="flex" justify="start" gutter={0} style={{ margin: 20 }}>

                <Title level={5}>Number of friends referred: {props.authUser.referral_count || 0}</Title>
            </Row>



        </Card>

    </>
}





export default App;

