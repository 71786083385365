import React, { useState, useEffect, Fragment, forwardRef, useImperativeHandle } from 'react';
// import { Link } from 'react-router-dom';
// import Highlighter from 'react-highlight-words';
// import elasticlunr from 'elasticlunr';

import { Input, Skeleton, Tree } from "antd";

// import {
//     SearchOutlined,
//     EditTwoTone,
//     DeleteTwoTone,
//     CheckOutlined,
//     CloseOutlined,
//     DownOutlined
// } from '@ant-design/icons';

import { makeCancelable, useFirebase } from '../Hooks';

// import TwoInputs from './title-input';
import { sleep } from '../Hooks';
// import { GiConsoleController } from 'react-icons/gi';

// const { Search } = Input;






// function updateTreeData(list, key, children) {
//     return list.map((node) => {
//         if (node.key === key) {
//             return { ...node, children };
//         }

//         if (node.children) {
//             return { ...node, children: updateTreeData(node.children, key, children) };
//         }

//         return node;
//     });
// }

let pendingPromises = [];


const appendPendingPromise = promise =>
    pendingPromises = [...pendingPromises, promise];

const removePendingPromise = promise =>
    pendingPromises = pendingPromises.filter(p => p !== promise);









const TocTree = forwardRef((props, ref) => {

    // const { data, loading, error, refresh, setRefresh } =
    //     useFirebase(props.firebase.db.ref(`userposts/${props.authUser.uid}`).orderByChild('parent').equalTo(null));

    // const [treeData, setTreeData] = useState(props.initialData);

    // const [expandedKeys, setExpandedKeys] = useState([]);
    // const [loadedKeys, setLoadedKeys] = useState([]);
    // const [selected, setSelected] = useState([]);
    // const [loadedKeys, setLoadedKeys] = useState([]);
    // const [searchValue, setSearchValue] = useState('');
    // const [autoExpandParent, setAutoExpandParent] = useState(true);

    // let loadedKeys;


    // const getParentKey = (key, tree) => {
    //     let parentKey;
    //     for (let i = 0; i < tree.length; i++) {
    //         const node = tree[i];
    //         if (node.children) {
    //             if (node.children.some(item => item.key === key)) {
    //                 parentKey = node.key;
    //             } else if (getParentKey(key, node.children)) {
    //                 parentKey = getParentKey(key, node.children);
    //             }
    //         }
    //     }
    //     return parentKey;
    // };




    async function updateTreeData(list, key) {

        // let newnodes = [];

        return await list.map(async (node) => {
            if (node.key === key) {

                const cids = node.childrenids;
                const queries = cids ? cids.map(cid => props.firebase.db.ref(`${props.dbrefname}/${props.authUser.uid}/${cid}`)) : null;


                if (queries) {

                    const wrappedPromises = queries.map(q => makeCancelable(q.once("value")));
                    wrappedPromises.forEach(w => appendPendingPromise(w));

                    return Promise.all(wrappedPromises.map(w => w.promise))
                        .then(snapshots => {
                            const children = snapshots.map(s => {
                                const v = s.val();
                                const childrenids = (v.children && Object.keys(v.children)) || [];
                                const result = childrenids.length > 0 ? { title: v.name, key: s.key, childrenids } : { title: v.name, key: s.key, isLeaf: true };
                                props.addNodeData(s.key, v);
                                return result;
                            });
                            // console.log('children now is', children);
                            wrappedPromises.forEach(w => removePendingPromise(w));
                            // const newResult = updateTreeData(treeData, key, newdata);
                            // setTreeData(newResult);
                            return node.children ? [...node.children, ...children] : children;
                        })
                        .then((children) => {
                            // setLoadedKeys(lk => [...lk, key]);
                            // setExpandedKeys(ek => [...ek, key]);
                            return { ...node, children };
                        })
                        .catch(errorInfo => {
                            if (!errorInfo.isCanceled) {
                                console.log(errorInfo.error);
                                // this.setState({ error: errorInfo.error, loading: false });
                                wrappedPromises.forEach(w => removePendingPromise(w));
                                // return { ...node }
                            }
                        });

                }

                // console.log('results is', results);
                // return results;

            }

            if (node.children) {
                const promises = await updateTreeData(node.children, key);
                const wrappedPromises = promises.map(q => makeCancelable(q));
                wrappedPromises.forEach(w => appendPendingPromise(w));

                return Promise.all(wrappedPromises.map(w => w.promise))
                    .then(s => {
                        // console.log('s in child is', s);
                        wrappedPromises.forEach(w => removePendingPromise(w));
                        return { ...node, children: s };
                    })
            }

            return node;

        });

    };




    // const dataList = [];

    // const loop = data =>
    //     data.map(item => {
    //         const index = item.title.indexOf(searchValue);
    //         const beforeStr = item.title.substr(0, index);
    //         const afterStr = item.title.substr(index + searchValue.length);
    //         const title =
    //             index > -1 ? (
    //                 <span>
    //                     {beforeStr}
    //                     <span className="site-tree-search-value">{searchValue}</span>
    //                     {afterStr}
    //                 </span>
    //             ) : (
    //                 <span>{item.title}</span>
    //             );
    //         if (item.children) {
    //             return { title, key: item.key, children: loop(item.children) };
    //         }

    //         return {
    //             title,
    //             key: item.key,
    //         };
    //     });


    // const generateList = data => {
    //     console.log(data);
    //     for (let i = 0; i < data.length; i++) {
    //         const node = data[i];
    //         console.log(node);
    //         const { key } = node;
    //         dataList.push({ key, title: key });
    //         if (node.children) {
    //             generateList(node.children);
    //         }
    //     }
    // };

    // generateList(treeData);


    const onSelect = (keys, e) => {
        props.onSelect(keys);
        // setSelected(keys);
        // console.log(e);
        // e.node.title = 'testing';
    }



    const childNodesDeep = (nodes, arr) => {
        if (nodes) {
            nodes.forEach((ele) => {
                arr.push(ele.id);
                if (ele.children) {
                    childNodesDeep(ele.children, arr);
                }
            });
        }
    }

    const getChild = (nodes, targetId, arr) => {
        for (const el of nodes) {
            if (el.id === targetId) {
                arr.push(el.id);
                if (el.children) {
                    childNodesDeep(el.children, arr);
                }
            } else if (el.children) {
                getChild(el.children, targetId, arr);
            }
        }
        return arr;
    }

    const onLoad = (ekeys, something) => {

        // console.log('$$$$$$$$$$$$$$$$$$$$$$in onLoad, ekeys', ekeys, 'soemthing', something);
        // sleep(10000);

    }


    const onExpand = (eKeys, { expanded, node }) => {

        const key = node.key;

        if (!expanded) { // 收起时
            // const childKeys = getChild(treeData, key, []); // 获取当前节点下所有子节点（这里会包含自身）
            // console.log('here, compare childKeys', childKeys, 'and self', node.key);
            // setLoadedKeys(loadedKeys.filter(item => !childKeys.includes(item)));
            // setExpandedKeys(expandedKeys.filter(item => !childKeys.includes(item)));

            props.setLoadedKeys(props.loadedKeys.filter(item => item !== key));

            props.setExpandedKeys(props.expandedKeys.filter(item => item !== key));
        } else { // 展开时
            props.setExpandedKeys(eKeys);
            props.setLoadedKeys(eKeys);
        }

        // this causes the onLoad to keep being called, although onExpand was only called once


        // setExpandedKeys(eKeys);
        // setAutoExpandParent(true);

    };

    // const onChange = e => {
    //     const { value } = e.target;
    //     const newExpandedKeys = dataList
    //         .map(item => {
    //             if (item.title.indexOf(value) > -1) {
    //                 return getParentKey(item.key, treeData);
    //             }
    //             return null;
    //         })
    //         .filter((item, i, self) => item && self.indexOf(item) === i);

    //     setExpandedKeys(newExpandedKeys);
    //     setSearchValue(value);
    //     setAutoExpandParent(true);
    // };


    const onDragEnter = info => {
        // console.log(info);


        // ideally I should also expand the key if drop enters
        // loadData(info);
        // setExpandedKeys([...info.expandedKeys, info.node.key]);

        // console.log([...info.expandedKeys, info.node.key]);
        onExpand([...info.expandedKeys, info.node.key], { expanded: true, node: info.node });
        props.setExpandedKeys([...info.expandedKeys, info.node.key]);

        // expandedKeys 需要受控时设置
        // this.setState({
        //   expandedKeys: info.expandedKeys,
        // });
    };

    const onDrop = info => {
        // console.log(info);
        const dropKey = info.node.key;
        // console.log('dropkey is', dropKey);

        const dragKey = info.dragNode.key;
        // console.log('dragKey is', dragKey);

        props.onSibling(dragKey, dropKey);
        const dropPos = info.node.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

        const loop = (data, key, callback) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].key === key) {
                    return callback(data[i], i, data);
                }
                if (data[i].children) {
                    loop(data[i].children, key, callback);
                }
            }
        };

        const data = [...props.treeData];

        // Find dragObject
        let dragObj;
        loop(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1);
            dragObj = item;
        });

        if (!info.dropToGap) {
            props.onParent(dragKey, dropKey);
            // Drop on the content
            // console.log('not dropToGap, info:', info);
            // only add if this item is known to be a leaf; otherwise we should wait for children to load
            if (info.node.isLeaf) {
                loop(data, dropKey, item => {
                    item.children = item.children || [];
                    item.isLeaf = false;
                    // where to insert 示例添加到头部，可以是随意位置
                    item.children.unshift(dragObj);
                    props.setExpandedKeys([...props.expandedKeys, item.key]);
                    props.setLoadedKeys([...props.loadedKeys, item.key]);
                });
            } else if (info.node.children && info.node.children.length) {
                loop(data, dropKey, item => {
                    item.children = item.children || [];
                    item.isLeaf = false;
                    // where to insert 示例添加到头部，可以是随意位置
                    item.children.unshift(dragObj);
                });
            } else {
                //how about add child first, and then load more children?
                loop(data, dropKey, item => {
                    item.children = item.children || [];
                    item.isLeaf = false;
                    // where to insert 示例添加到头部，可以是随意位置
                    item.children.unshift(dragObj);
                });
                // onExpand([...expandedKeys, info.node.key], { expanded: true, node: info.node });
            }

            // else {
            //     onDragEnter(info);
            //     loop(data, dropKey, item => {
            //         item.children = item.children || [];
            //         item.isLeaf = false;
            //         // where to insert 示例添加到头部，可以是随意位置
            //         item.children.unshift(dragObj);
            //     });
            // }
        } else if (
            // (info.node.props.children || []).length > 0 && // Has children
            (info.node.children || []).length > 0 && // Has children
            info.node.props.expanded && // Is expanded
            dropPosition === 1 // On the bottom gap
        ) {
            loop(data, dropKey, item => {
                item.children = item.children || [];
                // where to insert 示例添加到头部，可以是随意位置
                item.children.unshift(dragObj);
                // in previous version, we use item.children.push(dragObj) to insert the
                // item to the tail of the children
            });
        } else {
            let ar;
            let i;
            loop(data, dropKey, (item, index, arr) => {
                ar = arr;
                i = index;
            });
            if (dropPosition === -1) {
                ar.splice(i, 0, dragObj);
            } else {
                ar.splice(i + 1, 0, dragObj);
            }
        }

        props.setTreeData(data);
    };




    function loadData({ key, children }) {

        return new Promise((resolve) => {
            if (children) {
                console.log('loadDAta has children');
                resolve();
                return;
            }

            // setTimeout(() => {




            // setTreeData((origin) => {
            //     const s = updateTreeData(origin, key);

            //     console.log('s is', s);

            //     return Promise.all(s).then(newdata => {
            //         console.log('new data is', newdata);
            //         return newdata
            //     });

            //     // return s;

            //     // .then(s => {
            //     //     console.log('neworigin', s);
            //     //     return Promise.all(s);
            //     // });
            // }
            // );


            updateTreeData(props.treeData, key).then(s => {
                Promise.all(s).then(newdata => props.setTreeData(newdata));

            });

            resolve();
            // }, 1000);
        });

    }




    // const addChild = (newchild, key) => {

    //     if (key === null) {
    //         props.setTreeData(oldTree => [newchild, ...oldTree]);
    //         return;
    //     }

    //     props.setTreeData(oldTree => {
    //         const oldTreeCopy = [...oldTree];
    //         const addChild = (node) => {
    //             node.children = node.children ? [newchild, ...node.children] : [newchild];
    //             node.isLeaf = false;
    //         }
    //         findChildDo(key, oldTreeCopy, addChild);
    //         return oldTreeCopy;
    //     });

    //     setLoadedKeys(lk => { if (!lk.includes(key)) { return [...lk, key] } else { return lk } });
    //     setExpandedKeys(lk => { if (!lk.includes(key)) { return [...lk, key] } else { return lk } });
    //     // console.log('loaded', loadedKeys, 'expanded', expandedKeys);

    // }




    // useEffect(() => {
    // // if I keep this useEffect, then I can't further open up child nodes since the promises are cleaned up
    //     // console.log('in useEffect');
    //     // // Specify how to clean up after this effect:

    //     return function cleanup() {
    //         console.log('in cleanup');
    //         pendingPromises.map(p => p.cancel());
    //     };
    // });


    // useEffect(() => {
    //     // console.log('in useEffect');
    //     // // Specify how to clean up after this effect:

    //     setTreeData(treeData);

    // }, [props.treeData]);





    return <Fragment>
        {/* <Search style={{ marginBottom: 8 }} placeholder="Search Tree" onChange={onChange} /> */}


        {/* {loading ? <Skeleton active loading={loading} title={{ width: "20%" }} paragraph={{ rows: 3, width: Array(3).fill(400) }} round /> : */}
        <Tree
            loadData={loadData}
            treeData={props.treeData}
            // showLine
            className='post-browse-tree'
            // switcherIcon={<DownOutlined />}
            onSelect={onSelect}
            selectedKeys={props.selected}
            // showLine={{ showLeafIcon: false }}
            draggable
            blockNode
            onDragEnter={onDragEnter}
            onDrop={onDrop}
            onExpand={onExpand}
            onLoad={onLoad}
            loadedKeys={props.loadedKeys}
            expandedKeys={props.expandedKeys}
        // autoExpandParent={true}
        // treeData={loop(treeData)}

        />
        {/* } */}
    </Fragment>

        ;


});




export default TocTree;

// // unless data changes, keep the graph unchanged
// function moviePropsAreEqual(prevMovie, nextMovie) {
//     // console.log(prevMovie.treeData, nextMovie.treeData, prevMovie.treeData === nextMovie.treeData,
//     //     prevMovie.treeData[0] === nextMovie.treeData[0], prevMovie.treeData[1] === nextMovie.treeData[1],
//     //     prevMovie.treeData[3] === nextMovie.treeData[3], prevMovie.treeData[2] === nextMovie.treeData[2]
//     // );
//     return JSON.stringify(prevMovie.treeData) === JSON.stringify(nextMovie.treeData);
// }


// const MemoizedApp = React.memo(TocTree, moviePropsAreEqual);

// export default MemoizedApp;

