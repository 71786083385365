import React, { useState, useEffect, Fragment, forwardRef, useImperativeHandle } from 'react';

import {
    Button, message, Skeleton, Tooltip, Row, Col, Popover, Popconfirm, Spin
} from 'antd';

import {
    DeleteOutlined
} from "@ant-design/icons";

import { LimitInput } from '../Designs';

import Editor from "rich-markdown-editor";

import { customDark } from "./theme.ts";


import { AutosaveEditor } from './autosave-editor';

import moment from 'moment';
import Moment from 'react-moment';

import { useFirebase, useIsFirstRender } from '../Hooks';

import TitleInput from '../Posts/title-input';








const EditorScreen = forwardRef((props, ref) => {

    // const isFirstRender = useIsFirstRender();

    const [collapsed, setCollapsed] = useState(false);

    const [createVisible, setCreateVisible] = useState(false);

    const [showComments, setShowComments] = useState(false);
    const [addcomment, setAddcomment] = useState(false);
    const [commentInput, setCommentInput] = useState('');
    // const [showNotes, setShowNotes] = useState(false);
    const [ncomments, setNcomments] = useState(0);
    const [comments, setComments] = useState([]);
    const [commentsLoading, setCommentsLoading] = useState(false);

    const [nnotes, setNnotes] = useState(0);

    const [editing, setEditing] = useState(true);
    const [editingTitle, setEditingTitle] = useState(false);

    const [saving, setSaving] = useState(false);

    const [canRunIntervalCounter, setCanRunIntervalCounter] = useState(true);

    const { data, loading: readloading, error: readerror, refresh, setRefresh } = useFirebase(props.firebase.db.ref(`usercontents/${props.authUser.uid}/${props.itemId}`));

    const [editorState, setEditorState] = useState((data && data.content) || '');
    const [prevContent, setPrevContent] = useState('');

    // const [editingCommentReply, setEditingCommentReply] = useState(false);

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);




    const customUpload = async ({ onError, onSuccess, file }) => {

        console.log('file is', file);

        const storage = props.firebase.storage;
        const oldFileName = file.name.split(".").slice(0, -1).join(".");
        const storageRef = await storage.ref();
        const newFileName =
            oldFileName;
        //  + Math.round(Math.random() * 1000).toString(); //a unique name for the image
        // const newFileName = oldFileName;
        const url = `img/${newFileName}.png`;
        file.newurl = url;
        const imgFile = storageRef.child(url);

        try {
            const image = await imgFile.put(file);
            onSuccess(null, image);
            return url;
        } catch (e) {
            onError(e);
        }
    };


    const onEditorChange = (est) => {
        // console.log('editorChange is triggered, est is', est);
        setEditorState(est);
        // console.log(editorState.toRAW());
    }


    const doSubmit = async () => {

        const submitTime = Math.round(moment().format('X'));
        const content = editorState;
        // console.log('content is now', content);
        const submitObj = {
            submitTime,
            content
        };

        if (content && content.length > 0 && prevContent !== content) {
            setError(null);
            setLoading(true);
            const dbref = props.firebase.db.ref(`usercontents/${props.authUser.uid}/${props.itemId}`);

            // const fdbref = props.firebase.fdb.collection("usercontents").doc(props.authUser.uid).collection("contents").doc(props.itemId);

            dbref.update(submitObj)
                .catch(error => {
                    setError(error);
                    console.log('update error', error);
                    message.error(error.message, 2);
                }).then(() => {
                    setPrevContent(content);
                    message.success("Edits saved");
                });

            // fdbref.set(submitObj, { merge: true }).catch(error => {
            //     setError(error);
            //     console.log('update error for searchable edits', error);
            //     message.error(error.message, 2);
            // }).then(() => {
            //     message.success("Searchable Edits saved");
            // });

            setLoading(false);
        }

        // else {
        //     message.warn("No new content");
        // }
        return submitObj;
    }


    const onFinish = async () => {
        setSaving(true);
        doSubmit()
            .then(() => {
                setSaving(false);
                setEditing(false);
            })
    };

    const onIdle = () => {
        doSubmit();
        console.log("is idle now");
        setCanRunIntervalCounter(false);
    }

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };


    const handleClickComments = () => {
        setShowComments(!showComments);

        // if (ncomments === 0 && !showComments) {
        //     setAddcomment(true);
        // } else {
        //     setShowComments(!showComments);
        // }
    }


    const handleCommentChange = (e) => {
        setCommentInput(e.target.value);
    }

    const handleSubmitComment = async (toType, toItem, originalCommentId, post_uid, text) => {
        const submitTime = Math.round(moment().format('X'));

        // const contentObject = convertEditorStateToRaw(editorState);  // this is saved as object
        // const htmlContent = editorState.toHTML();
        // const contentString = editorState.toRAW();
        // const texts = contentObject.blocks.map(b => b.text).join(' ');
        // const newlen = texts.length;

        const submitObj0 = {
            submitTime,
            text,
            toType,
            toItem: toItem,
            fromUser: props.authUser.uid,
        };

        const submitObj = {
            ...(!!originalCommentId && { originalCommentId }),
            ...submitObj0
        }

        setAddcomment(false);

        if (text.replace(/ /g, '').length > 0) {
            setError(null);
            setLoading(true);
            const dbref = props.firebase.db.ref(`comments/${props.itemId}`);
            if (ncomments === 0) {
                await dbref.update({ post_uid })
            }
            const key = await dbref.child('comments').push(submitObj)
                .catch(error => {
                    setError(error);
                    console.log('update error', error);
                    message.error(error.message, 2);
                }).then((snap) => {
                    message.success("Edits saved");
                    setComments([...comments, { ...submitObj, cid: snap.key, likes_count: 0 }]);
                    return snap.key
                })

            if (toType === 'post') {
                dbref.child('children').update({ [key]: submitTime })
                    .catch(error => {
                        setError(error);
                        console.log('update error', error);
                        message.error(error.message, 2);
                    }).then((snap) => {
                        message.success("Child saved");
                    });
            } else {
                const origId = originalCommentId || toItem;
                dbref.child('comments').child(origId).child('children').update({ [key]: submitTime })
                    .catch(error => {
                        setError(error);
                        console.log('update error', error);
                        message.error(error.message, 2);
                    }).then((snap) => {
                        message.success("Child saved");
                    });

            }
            setNcomments(ncomments + 1);
            setLoading(false);
            setCommentInput('');

        }
        return submitObj;


    }

    const onCollapse = collap => {
        setCollapsed(collap);
        // this.setState({ collapsed });
    };

    const onTitlePressEnter = e => {
        e.preventDefault();
        const val = e.target.value;
        if (val.replace(/ /g, '').length > 0 && val.trim() !== props.itemTitle) {
            props.onTitlePressEnter(val.trim(), props.itemId);
        } else if (val.replace(/ /g, '').length > 0) { setEditingTitle(false); }

    }



    const commentData = comments.map(i => ({
        // actions: actions('-eregege'),
        author: 'Han Solo',
        avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
        content: (
            <p>
                {i.text}
            </p>
        ),
        datetime: (
            <Tooltip
                title={moment(i.submitTime * 1000).format('MMMM Do YYYY, h:mm:ss a')}
            >
                <span>
                    {<Moment interval={30000} fromNow>
                        {i.submitTime * 1000}
                    </Moment>}
                </span>
            </Tooltip>
        ),
        cid: i.cid,
        postId: props.itemId,
        likes_count: i.likes_count,
        like: i.like || false,
        dislike: i.dislike || false,
        flag: i.flag || false,
        ...(!!i.originalCommentId && { originalCommentId: i.originalCommentId })

    }));


    const handleChildCreate = async (name) => {
        await props.handleChildCreate(name, props.itemId);
        setCreateVisible(false);
    }

    const handleCreate = name => {
        doSubmit();
        handleChildCreate(name);

    }

    const handleVisibilityChange = () => {
        setCreateVisible(!createVisible);

    }

    useImperativeHandle(ref, () => ({

        async savePost() {
            await doSubmit();
        }

    }));


    // useEffect(() => {

    //     if (!isFirstRender) {

    //         console.log('data is', data);

    //         if (data && Object.keys(data).length > 0) {

    //             let isSubscribed = true;

    //             // console.log('getting editor data');

    //             // const content = Object.values(data)[0];

    //             if (isSubscribed) {

    //                 setEditorState(data.content || '');

    //                 // if (data.comment_count) {
    //                 //     setNcomments(data.comment_count);
    //                 // }
    //                 // if (data.nnotes) {
    //                 //     setNcomments(data.nnotes);
    //                 // }
    //             }

    //             return () => (isSubscribed = false);
    //         } else {
    //             setEditing(true);
    //         }

    //     }

    // }, [data]);


    // useEffect(() => {

    //     if (showComments === true) {

    //         let isSubscribed = true;

    //         if (isSubscribed) {

    //             setError(null);
    //             setCommentsLoading(true);

    //             const dbref = props.firebase.db.ref(`comments/${props.itemId}/comments`);

    //             const usinRef = props.firebase.db.ref(`userinteracts/${props.authUser.uid}/${props.itemId}/comments/`);

    //             Promise.all([dbref.once("value"), usinRef.once("value")])
    //                 .then(snapshots => snapshots.map(s => s.val()))
    //                 .then(rawdata => {
    //                     if (Object.keys(rawdata[0]).length > 0) {

    //                         const commentsData = Object.assign(...Object.entries(rawdata[0]).map(([k, t], i) => ({
    //                             [k]: {
    //                                 ...(!!t.originalCommentId && { originalCommentId: t.originalCommentId }),
    //                                 cid: k,
    //                                 submitTime: t.submitTime,
    //                                 // submitTimeCal: moment(t.submitTime * 1000).format('MMMM Do YYYY, h:mm:ss a'),
    //                                 // submitTimeText: moment(t.submitTime * 1000).fromNow(),
    //                                 text: t.text,
    //                                 toItem: t.toItem,
    //                                 toType: t.toType,
    //                                 fromUser: t.fromUser,
    //                                 likes_count: t.likes_count || 0,
    //                             }
    //                         })));

    //                         const interData = Object.assign(...Object.entries(rawdata[1]).map(([k, t], i) => ({
    //                             [k]: {
    //                                 like: t.like,
    //                                 dislike: t.dislike,
    //                                 flag: t.flag,
    //                             }
    //                         })));

    //                         const tableData = Object.entries(commentsData).map(([k, v], i) => ({
    //                             ...v,
    //                             ...(!!interData[k] && interData[k])
    //                         }
    //                         ));


    //                         setComments(tableData);
    //                         setCommentsLoading(false);
    //                     }
    //                 })
    //                 .catch(errorInfo => {
    //                     if (!errorInfo.isCanceled) {
    //                         setError(errorInfo.error);
    //                         setCommentsLoading(false);
    //                     }
    //                 });


    //         }

    //         return () => (isSubscribed = false);
    //     }

    // }, [showComments]);

    const onDelete = () => {
        props.onDelete(props.itemId);
    }


    useEffect(() => {

        setRefresh(refresh + 1);
        setEditingTitle(false);

    }, [props.itemId]);


    return (



        <Fragment>

            <Row type="flex" justify="center" style={{ marginTop: 10, marginBottom: 10 }}>
                {editingTitle ? <Row className="titleEdit" type="flex" justify="center">
                    <LimitInput
                        allowClear
                        maxLength={props.maxTitleLength}
                        placeholder='Title'
                        defaultValue={props.itemTitle}
                        onPressEnter={onTitlePressEnter}
                        onBlur={onTitlePressEnter}
                        style={{ width: `${props.itemTitle.length * 1.8 + 12}ch` }}
                    /> </Row> :
                    <h1 onClick={() => setEditingTitle(true)} id='title'
                        className="postTitle"
                    >{props.itemTitle}</h1>
                }
            </Row>



            {readloading ? <Skeleton active title paragraph={{ rows: 5 }} /> : <Skeleton active title loading={!data && data !== null} paragraph={{ rows: 5 }}>

                <Fragment>
                    <Row type="flex" justify="start" align="middle" style={{ paddingLeft: 12, paddingRight: 12 }}>
                        <Col span={6} style={{ fontSize: 14 }}>
                            {data && <span>Last updated < Moment interval={30000} fromNow>
                                {data.submitTime * 1000}
                            </Moment></span>}
                        </Col>
                        <Col span={3} offset={12}>
                            <Popover
                                content={
                                    <Row type="flex" justify="center" style={{ width: 400 }}>
                                        <TitleInput
                                            focusOnRender={true}
                                            style={{ width: "100%" }}
                                            maxNameLength={props.maxTitleLength}
                                            handleCreate={handleCreate}
                                        />
                                    </Row>
                                }
                                trigger="click"
                                placement="bottomLeft"
                                visible={createVisible}
                                onVisibleChange={handleVisibilityChange}
                                style={{ width: "100%" }}
                            >
                                <Button type="dashed">Create a Sub-post</Button>
                            </Popover>
                        </Col>
                        <Col span={1} offset={1}>
                            <Popconfirm
                                title="Are you sure about deleting this post?"
                                onConfirm={onDelete}
                                // onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Col>
                    </Row>



                    {/* <Button type="primary" style={{ marginTop: 0 }} block onClick={editing ? onFinish : () => setEditing(true)}>{editing ? "Save" : "Modify"}</Button> */}
                    <div style={{ padding: 40, width: "100%" }}>


                        <AutosaveEditor {...props}

                            interval={1000 * 60 * 0.5}
                            // editorPlaceholder={`Maximum ${maxTitleLength} characters`}
                            doSubmit={doSubmit}
                            autoSubmit={doSubmit}
                            isIntervalCounterRunning={canRunIntervalCounter}
                            SetIsIntervalCounterRunning={setCanRunIntervalCounter}
                            onIdle={onIdle}
                        >

                            <Editor
                                autoFocus
                                id='main-editor'
                                // language='en'
                                // value={editorState}
                                // controls={[]}
                                // readOnly={true}
                                // contentStyle={{ height: "auto", maxHeight: "none", wordBreak: "keep-all" }}
                                // onFocus={() => setEditing(true)}


                                onChange={onEditorChange}
                                placeholder="Edit your post here"
                                theme={customDark}
                                style={{ fontSize: "14px" }}
                                defaultValue={(data && data.content) || ''}
                                onSave={doSubmit}
                                readOnly={!editing}
                                uploadImage={async file => {
                                    const onSuccess = (_, image) => { console.log(image) };
                                    const onError = () => message.error("failed at uploading image");
                                    const result = await customUpload({ onError, onSuccess, file });
                                    return result.url;
                                }}
                            />

                        </AutosaveEditor>


                    </div>
                    {/* {editing ? <Button type="primary" style={{ marginTop: 40 }} block loading={loading} onClick={onFinish}>Save</Button> : <div></div>} */}

                </Fragment>
            </Skeleton>

            }

        </Fragment >

    )

});



export default EditorScreen;




