import React from 'react';
import {
    Result, Button
} from 'antd';

import * as ROUTES from "../../constants/routes";

const Page = (props) => <Result
    status="403"
    title="Login required"
    subTitle="To have access to this page, please log in."
    extra={<Button key="home" type="primary" onClick={() => { props.history.push(ROUTES.LANDING) }}>Go Back</Button>}
/>;

export default Page;


