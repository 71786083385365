import React from 'react';
// import { compose } from 'recompose';
import {
    Button, Input, AutoComplete, Collapse, Row, Skeleton
} from 'antd';
import Icon from '@ant-design/icons';
import elasticlunr, { saveIndex } from 'elasticlunr';
// import { Link } from 'react-router-dom';
// import { HashLink } from 'react-router-hash-link';
// import {sleep} from '../Hooks';

// import { withFirebase } from '../Firebase';
// import * as ROUTES from '../../constants/routes';

// import {
//     AuthUserContext,
//     withAuthorization,
//     withEmailVerification,
// } from '../Session';

import { faq } from '../../constants/faq';


const Option = AutoComplete.Option;
const Panel = Collapse.Panel;


function renderOption(item) {
    return (
        <Option key={item.ref} text={faq[item.ref].title}>

            {/* <HashLink to={`${ROUTES.FAQ}#faq_${item.ref}`}>{faq[item.ref].title}</HashLink> */}
            <span className="global-search-item-count">约 {item.count} 个结果</span>
        </Option>
    );
}

class Complete extends React.Component {
    state = {
        dataSource: [],
        expand: ['0'],
        index: null,
        loading: false,
    }


    handleSearch = (value) => {
        this.setState({
            dataSource: value && this.state.index ? this.searchResult(value, this.state.index) : [],
        });
    }


    onSelect = (value) => {
        this.setState({ expand: [...this.state.expand, value] });
    }

    onChange = (value) => {
        console.log(value);
        this.setState({ expand: value });
    }

    searchResult = (query) => {
        return this.state.index && this.state.index.search(query, {})
            .map((item, idx) => ({
                query,
                ref: item.ref,
                category: `${query}${idx}`,
                // count: getRandomInt(200, 100),
            }));
    }


    async componentDidMount() {

        await this.setState({ loading: true });

        const index = elasticlunr(function () {
            this.addField('title');
            this.addField('body');
            this.setRef('id');
            // Include product data with the index data.
            // //   this.saveDocument(true);

            faq.forEach((f, i) => {
                console.log({ ...f, id: i });
                this.addDoc({ ...f, id: i });
            });

        });


        // saveIndex(JSON.stringify(index)); 

        // fetch('data/index.json').then(response => {
        //     return response.json();
        //   }).then(json => {
        //     index = elasticlunr.Index.load(json);
        //   });


        // await sleep(2000);


        this.setState({ index, loading: false });

    }


    render() {
        const { dataSource, expand } = this.state;
        const faqs = faq.map((f, i) =>
            // <div key={i} id={`faq_${i}`} style={{marginTop: 20, marginBottom: 20}}>
            // <h3></h3>
            <Panel header={f.title} key={i} id={`faq_${i}`}>
                <span>{f.body}</span>
            </Panel>

            // </div>
        );

        return (
            <div style={{ maxWidth: 1200, margin: "auto", paddingLeft: 24, paddingRight: 24 }}>
                <Row type="flex" justify="center" style={{ marginTop: 20 }}>
                    <h1> Frequently Asked Questions </h1>
                </Row>
                {this.state.loading ? <div style={{ marginTop: 60 }}><Skeleton active paragraph={{ rows: 10 }} title={{ width: "60%" }} /></div> :
                    <React.Fragment>
                        <Row type="flex" justify="center" style={{ marginTop: 20 }}>
                            <AutoComplete
                                className="global-search"
                                size="large"
                                style={{ width: '100%', maxWidth: 600, marginTop: 20, marginBottom: 20 }}
                                dataSource={dataSource.map(renderOption)}
                                onSelect={this.onSelect}
                                onSearch={this.handleSearch}
                                placeholder="Search the FAQ"
                                optionLabelProp="text"
                            >
                                <Input
                                    suffix={(
                                        <Button className="search-btn" size="large" type="primary">
                                            <Icon type="search" />
                                        </Button>
                                    )}
                                />
                            </AutoComplete>
                        </Row>
                        {/* <Row type="flex" justify="center" style={{ marginTop: 20 }}></Row> */}
                        <Collapse bordered={false} activeKey={expand} onChange={this.onChange}>
                            {faqs}
                        </Collapse>
                    </React.Fragment>}
            </div>
        );
    }
}



// const faqBase = (props)  => {

//     const {data, loading} = useFirebase(props.firebase, props.rootname, props.authUser.uid);




//     console.log(pref.data);



//     return(
//     <div style={{ maxWidth: 800, margin: "auto", paddingLeft: 24, paddingRight:24 }}/>
//     )

//     }


export default Complete;