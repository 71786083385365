import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Menu, Row, Button } from "antd";
import Icon, {
  BellOutlined,
  UserOutlined,
  LogoutOutlined,
  // LoginOutlined,
  HomeOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import { BsClockHistory } from "react-icons/bs";

import logo from "../../constants/secresilk-logo.png";

import * as ROUTES from "../../constants/routes";
import { AuthUserContext } from "../Session";
import SignInForm from "../SignIn";
import SignUpForm from "../SignUp";
import PasswordForgetForm from "../PasswordForget";
import { withFirebase } from "../Firebase";
// import { TargetIcon, PunchcardIcon } from "../Icons";



// const SubMenu = Menu.SubMenu;

// const SigninMenu = SignInForm(Menu.Item);
const modalList = ["signin", "signup", "pwforget"];

// Place react component in menubar; vanilla html would result in many console errors
const LogoImage = () => (
  <img style={{ height: "50px", paddingLeft: 12 }} src={logo} alt="Logo" />
);

class NavigationBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: "login",
      // signinSpin: false,
      // signinVisible: (this.props.location && this.props.location.state && this.props.location.state.visible) || false,
      signinVisible: false,
      signupVisible: false,
      pwforgetVisible: false,
    };
  }

  handleClick = (e) => {
    // console.log('click ', e);
    this.setState({
      current: e.key,
    });
  };

  showModal = (key) => {
    const obj = Object.assign(
      ...modalList.map((i) => ({ [`${i}Visible`]: i === key }))
    );
    this.setState(obj);
  };

  cancelModal = (key) => {
    const stateName = `${key}Visible`;

    this.setState({
      [stateName]: false,
    });
  };

  onLogout = () => {
    this.props.firebase.doSignOut();
    this.props.history.push(ROUTES.LANDING);

  }

  render() {
    const { signinVisible, signupVisible, pwforgetVisible } = this.state;

    return (
      <React.Fragment>
        <SignUpForm
          visible={signupVisible}
          handleCancel={() => this.cancelModal("signup")}
        />
        <SignInForm
          visible={signinVisible}
          handleCancel={() => this.cancelModal("signin")}
          handleSignup={() => this.showModal("signup")}
          handlePwforget={() => this.showModal("pwforget")}
        />
        <PasswordForgetForm
          visible={pwforgetVisible}
          handleCancel={() => this.cancelModal("pwforget")}
        />

        <Row type="flex" justify="center" style={{ marginLeft: 12 }} gutter={60}>
          <AuthUserContext.Consumer>
            {(authUser) =>
              authUser ? (
                <Menu
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                  mode="horizontal"
                  style={{ backgroundColor: "transparent", width: "100%" }}
                >
                  {/* <Menu.Item key="settings">
                    <Link to={ROUTES.SETTINGS}>
                      <SettingOutlined />&nbsp;&nbsp;&nbsp;Settings
                    </Link>
                  </Menu.Item> */}
                  <Menu.Item key="home">
                    <Link to={ROUTES.HOME}>
                      <HomeOutlined />&nbsp;&nbsp;&nbsp;Home
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item key="write">
                    <Link to={ROUTES.WRITE}>
                      <EditOutlined />&nbsp;&nbsp;&nbsp;Write
                    </Link>
                  </Menu.Item> */}
                  <Menu.Item key="posts">
                    <Link to={ROUTES.POSTS}>
                      <Icon component={BsClockHistory} />&nbsp;&nbsp;&nbsp;
                      Posts
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="profile">
                    <Link to={ROUTES.PROFILE}>
                      <UserOutlined />&nbsp;&nbsp;&nbsp;Profile
                    </Link>
                  </Menu.Item>
                  {/* </SubMenu> */}
                  <Menu.Item key="help">
                    <Link to={ROUTES.HELP}>
                      <QuestionCircleOutlined />&nbsp;&nbsp;&nbsp;Help
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="notifications">
                    <Link to={ROUTES.NOTICE}>
                      <BellOutlined />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="logout" onClick={this.onLogout}><LogoutOutlined />&nbsp;&nbsp;&nbsp;Log Out</Menu.Item>
                </Menu>
              ) : (
                <Menu
                  // onClick={this.handleClick}
                  // selectedKeys={[this.state.current]}
                  mode="horizontal"
                  style={{ backgroundColor: "transparent" }}
                >
                  <Menu.Item
                    key="login"
                  // onClick={() => this.showModal("signin")}
                  >
                    {/* <LoginOutlined />
                    Log In/Sign up */}
                    <Button type="primary" onClick={() => this.showModal("signin")}>Log In/Sign up
                    </Button>
                  </Menu.Item>
                  {/* <Button type="primary" onClick={() => this.showModal("signin")}
                  >Log In/Sign up
                  </Button> */}
                </Menu>
              )
            }
          </AuthUserContext.Consumer>
        </Row>

      </React.Fragment>
    );
  }
}

const Navigation = compose(withRouter, withFirebase)(NavigationBase);

export default Navigation;
