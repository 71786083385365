import React, { Fragment, useEffect, useRef, createRef } from 'react';
// import ReactDOM from 'react-dom';
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';
import PropTypes from 'prop-types';

import {
  message, Spin, Row, Radio
} from 'antd';

import Icon, {
  QuestionCircleOutlined,
} from '@ant-design/icons';


import { VscTrash } from 'react-icons/vsc';


import { makeCancelable } from '../Hooks';
import { LimitInput } from '../Designs';
// const { TextArea } = Input;

const className = 'list-sort-demo';



const ListItem = (props) => {

  // const isFirstRender = useIsFirstRender();
  const itemRef = useRef();
  const { item, onDelete } = props;
  const className = 'list-sort-demo';
  const {
    title, itemKey
  } = item;

  // const icon = props.completed ? CheckCircleOutlined : QuestionCircleOutlined;

  // const color = props.completed ? '#52c41a' : 'rgb(131, 123, 123)';

  // const [editing, setEditing] = useState(false);



  const onClickEdit = (e) => {

    e.preventDefault();
    e.stopPropagation();
    props.onClickEdit(itemKey);
    // setEdit(true);
  }

  const onEditEnter = (e) => {
    props.onEditEnter(e, itemKey);
    // setEdit(false);
  }


  const handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete(itemKey);
  }

  // const onClickInput = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();

  // }


  const prefix = (
    <Icon component={VscTrash} onClick={handleDelete} />
  );

  // useEffect(() => {

  //   if (props.edit) {
  //     console.log(itemRef.current.);
  //     // itemRef.current.focus();
  //   }

  // }, [props.edit]);


  useEffect(() => {

    if (props.edit) {

      itemRef.current && itemRef.current.focus();
    }

  }, [props.edit]);

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
    marginLeft: '10px',
    width: '400px',
  };



  const coreitem = props.edit ?
    <LimitInput
      ref={itemRef}
      className={'list-sort-input'}
      // size="small"
      onPressEnter={onEditEnter}
      allowClear
      defaultValue={title}
      style={{
        height: 30,
        width: "90%",
        //   marginLeft: 20,
        //   marginTop: 0,
        //   marginBottom: 0,
        position: "absolute",
        top: "50%",
        MsTransform: "translateY(-50%)",
        transform: "translateY(-50%)"
      }}
      maxLength={props.maxcontentlength}
      prefix={prefix}
    /> :
    <span className={`${className}-text`}>
      <h1 onClick={onClickEdit} style={{ width: "370px" }}>{title}</h1>
    </span>

  // const cn = props.selected? `${className}-list-inner-selected`: `${className}-list-inner`;

  return <Radio value={itemKey} style={radioStyle}>
    {/* <span>
      {createElement(icon, {
        style: { color },
        className: `${className}-icon`
      })}
    </span> */}
    {coreitem}
  </Radio>
  //   </div>

  // <div 
  // className ={props.className}
  // // className={`${className}-list`}
  // // className={cn} 
  // // onClick={(e) => props.onOutsideClick(e, itemKey)}
  // >





  // </div>


}




export default class ListSort extends React.Component {
  static propTypes = {
    component: PropTypes.any,
    animType: PropTypes.string,
    onChange: PropTypes.any,
    dragClassName: PropTypes.string,
    appearAnim: PropTypes.object,
    onEventChange: PropTypes.any,
  };

  static defaultProps = {
    component: 'div',
    animType: 'y',
    onChange: () => {
    },
    dragClassName: null,
    onEventChange: () => {
    },
    appearAnim: null,
  };

  constructor(props) {
    super(props);
    this.parentRef = createRef();
    this.state = {
      options: [],
      order: [],
      children: [],
      style: {},
      childStyle: [],
      animation: [],
      inputContent: '',
      loading: true,
      error: null,
      editItem: null,
      itemloading: null,
      isDragging: false,
      completed: [],
      // sliderPos: 0,
      //   radiovalue: null,
    };
    this.index = null;
    this.swapIndex = null;
    this.mouseXY = null;
    this.childStyle = [];
    this.children = [];
    // this.isDragged = false;
  }


  order2data = (o, options) => ({
    icon: QuestionCircleOutlined,
    color: '#FF5500',
    title: (options.length + 1 >= o && options[o]) || '',
    itemKey: o,
  });



  data2child = item => {
    const {
      itemKey,
    } = item;

    const cn = this.props.completed ? `${className}-list-completed` : `${className}-list`;

    return (
      <div
        key={itemKey}
        // className={this.state.selected === itemKey ? `${className}-list-selected` : `${className}-list`}
        // className={`${className}-list`}
        className={cn}
      // onClick={(e) => this.onOutsideClick(e, itemKey)}
      >


        <ListItem
          className={cn}
          // getListItemContainer={node => node.parentNode}
          // overlayClassName={cn}
          key={itemKey}
          item={item}
          onEditEnter={this.onEditEnter}
          onClickEdit={this.onClickEdit}
          // onOutsideClick={this.onOutsideClick}
          edit={this.state.editItem === itemKey}
          completed={this.state.completed.includes(itemKey)}
          maxcontentlength={this.props.maxcontentlength}
          onDelete={this.onDelete}
        />

      </div>
    );
  }





  pendingPromises = [];

  componentWillUnmount = () =>
    this.pendingPromises.map(p => p.cancel());

  appendPendingPromise = promise =>
    this.pendingPromises = [...this.pendingPromises, promise];

  removePendingPromise = promise =>
    this.pendingPromises = this.pendingPromises.filter(p => p !== promise);

  componentDidMount() {

    // const datestr = moment().format('YYYYMMDD');
    const dbref = this.props.firebase[this.props.refname](`${this.props.authuser.uid}/${this.props.childname}`);

    // const recordsref = this.props.firebase[this.props.refname](`${this.props.authuser.uid}/records/${datestr}/${this.props.childname}`);


    const wrappedPromise = makeCancelable(dbref.once("value"));
    this.appendPendingPromise(wrappedPromise);

    // const wrappedRecPromise = makeCancelable(recordsref.once("value"));


    // this.appendPendingPromise(wrappedRecPromise);


    // Promise.all([wrappedPromise.promise, wrappedRecPromise.promise])

    wrappedPromise.promise
      .then(snapshot => snapshot.val())
      .then(termObject => {

        if (!termObject) {
          console.log('hre');

          this.setState({ loading: false }, () => {
            this.node = this.parentRef.current.querySelector(`.list-sort-demo div[refname=${this.props.refname}]`);
          });
        } else if (!!termObject.options) {

          const options = [...termObject.options];
          const order = !!termObject.order ? termObject.order : [...Array(termObject.options.length).keys()];

          this.setState({ options, order, loading: false }, () => {
            this.node = this.parentRef.current.querySelector(`.list-sort-demo div[refname=${this.props.refname}]`);
          });

        }


        this.removePendingPromise(wrappedPromise);
        // this.removePendingPromise(wrappedRecPromise);
      })
      .catch(errorInfo => {
        if (!errorInfo.isCanceled) {
          this.setState({ error: errorInfo.error });
          this.removePendingPromise(wrappedPromise);
          // this.removePendingPromise(wrappedRecPromise);
        }
      });


  }



  onDelete = itemKey => {

    this.setState((state, props) => ({
      order: state.order.filter(a => a !== itemKey),

    }), () => {

      console.log(this.state.order);

      const dbref = this.props.firebase[this.props.refname](`${this.props.authuser.uid}/${this.props.childname}`);
      this.setState({ error: null });

      dbref.child('order')
        .set(this.state.order)
        .catch(error => {
          console.log('update error', error);
          message.error(error.message, 2);
          this.setState({ error });
        })
        .then(() => {
          message.success("Order saved");
        })

    });

  }




  onClickEdit = itemKey => {

    if (this.state.isDragging) {
      return;
    }
    this.setState({ editItem: itemKey, itemloading: false });
    this.props.onClick()('', null);

  }





  onEditEnter = (e, itemKey) => {
    e.preventDefault();

    const val = e.target.value;
    const dbref = this.props.firebase[this.props.refname](`${this.props.authuser.uid}/${this.props.childname}`);
    const newOption = { [itemKey]: val };

    if (val.length > 0) {

      this.setState({ itemloading: itemKey });

      dbref.child('options')
        .update(newOption)
        .catch(error => {
          console.log('update error', error);
          message.error(error.message, 2);
          this.setState({ error, itemloading: null, editItem: null });
        }).then(() => {
          message.success("Edits saved");

          this.setState((state, props) => ({
            inputContent: '',
            options: Object.assign([], state.options, newOption),
            editItem: null,
            itemloading: false
          }),
          )
        });
    }
  }


  onMouseDown = (i, e) => {

    if (this.state.isDragging) {
      return;
    }
    // this.setState({ editItem: null });
    // console.log(this.node);
    const rect = this.node.getBoundingClientRect();
    // const rect = this.node.current.offsetHeight || 0;
    document.body.style.overflow = 'hidden';
    this.props.onEventChange(e, 'down');
    const style = {
      height: `${rect.height}px`,
      // height: `${rect}px`,
      userSelect: 'none',
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      MsUserSelect: 'none',
    };

    this.children = [...this.node.children];
    // console.log(this.children);
    this.childStyle = [];
    const childStyle = this.children.map((item, ii) => {
      const cItem = this.children[ii + 1];
      let marginHeight;
      let marginWidth;
      if (cItem) {
        marginHeight = cItem.offsetTop - item.offsetTop - item.clientHeight;
        marginWidth = cItem.offsetLeft - item.offsetLeft - item.clientWidth;
      } else {
        const parentHeight = item.parentNode.clientHeight
          - parseFloat(getComputedStyle(item.parentNode).getPropertyValue('padding-bottom'));
        const parentWidth = item.parentNode.clientWidth
          - parseFloat(getComputedStyle(item.parentNode).getPropertyValue('padding-right'));
        marginHeight = parentHeight - item.offsetTop - item.clientHeight;
        marginWidth = parentWidth - item.offsetLeft - item.clientWidth;
      }
      const d = {
        width: item.clientWidth,
        height: item.clientHeight,
        top: item.offsetTop,
        left: item.offsetLeft,
        margin: 'auto',
        marginHeight,
        marginWidth,
        position: 'absolute',
        zIndex: ii === i ? 1 : 0,
      };
      this.childStyle.push({ ...d });

      // console.log(this.childStyle);
      return d;
    });
    // this.childStyle = childStyle;
    const animation = this.children.map((item, ii) => (i === ii && (!this.props.dragClassName
      ? { scale: 1.2, boxShadow: '0 10px 10px rgba(0,0,0,0.15)', duration: 200 } : null)) || null);
    this.index = i;
    this.swapIndex = i;
    this.mouseXY = {
      startX: e.touches === undefined ? e.clientX : e.touches[0].clientX,
      startY: e.touches === undefined ? e.clientY : e.touches[0].clientY,
      top: childStyle[i].top,
      left: childStyle[i].left,
    };
    if (this.props.dragClassName) {
      this.listDom = e.currentTarget;
      this.listDom.className = `${this.listDom.className
        .replace(this.props.dragClassName, '').trim()} ${this.props.dragClassName}`;
    }
    // this.isDragged = true;
    this.setState({
      style,
      childStyle,
      animation,
    });
  };



  onMouseUp = (e) => {
    if (!this.mouseXY) {
      return;
    }
    this.mouseXY = null;
    document.body.style.overflow = null;
    this.props.onEventChange(e, 'up');
    const animation = this.state.animation.map((item, i) => {
      if (this.index === i) {
        const animate = { duration: 500 };
        // let height = 0;

        if (this.props.animType === 'y') {
          // console.log('anim type is y');
          if (this.swapIndex > this.index) {
            // console.log('swapindex larger', this.childStyle[this.swapIndex].top);
            // const start = this.index + 1;
            // const end = this.swapIndex + 1;
            // this.childStyle.slice(start, end).forEach((_item) => {
            //   console.log(_item.height);
            //   height += _item.height + _item.marginHeight;
            // });
            // animate.top = height + this.childStyle[this.index].top;
            animate.top = this.childStyle[this.swapIndex].top;
          } else {
            // console.log('swapindex not larger', this.childStyle[this.swapIndex].top);    //this appears to calculate the landing top position so as to smooth the landing
            animate.top = this.childStyle[this.swapIndex].top;
          }
        }

        const dragScale = !this.props.dragClassName
          && ({
            scale: 1,
            boxShadow: '0 0px 0px rgba(0,0,0,0)',
          });

        return {
          ...dragScale,
          ...animate,
          onComplete: () => {

            const callbackBool = this.index !== this.swapIndex;
            this.childStyle = [];
            this.setState((state, props) => ({
              style: {},
              childStyle: [],
              animation: [],
              order: this.sortArray(state.order, this.swapIndex, this.index),
              itemloading: false,
              isDragging: false,
            }), () => {
              // this.isDragged = false;
              if (callbackBool) {
                const dbref = this.props.firebase[this.props.refname](`${this.props.authuser.uid}/${this.props.childname}`);
                this.setState({ error: null });

                dbref.child('order')
                  .set(this.state.order)
                  .catch(error => {
                    console.log('update error', error);
                    message.error(error.message, 2);
                    this.setState({ error });
                  })
                  .then(() => {
                    message.success("Order saved");
                  })
              }
            });

            this.index = null;
            this.swapIndex = null;
          },
        };
      }
      return item;
    });
    if (this.props.dragClassName) {
      this.listDom.className = `${this.listDom.className
        .replace(this.props.dragClassName, '').trim()}`;
    }
    this.setState({ animation });
  };



  onMouseMove = (e) => {
    if (!this.mouseXY) {
      return;
    }
    this.setState({ isDragging: true, editItem: null, completed: [] });
    this.mouseXY.x = e.touches === undefined ? e.clientX : e.touches[0].clientX;
    this.mouseXY.y = e.touches === undefined ? e.clientY : e.touches[0].clientY;
    const childStyle = this.state.childStyle;
    let animation = this.state.animation;


    if (this.props.animType === 'x') {
      childStyle[this.index].left = this.mouseXY.x - this.mouseXY.startX + this.mouseXY.left;
    } else {
      childStyle[this.index].top = this.mouseXY.y - this.mouseXY.startY + this.mouseXY.top;
      this.swapIndex = childStyle[this.index].top < this.childStyle[this.index].top
        ? 0 : this.index;
      this.swapIndex = childStyle[this.index].top
        > this.childStyle[this.index].top + this.childStyle[this.index].height
        ? childStyle.length - 1 : this.swapIndex;

      const top = childStyle[this.index].top;
      this.childStyle.forEach((item, i) => {
        const cTop = item.top;
        const cHeight = item.height + item.marginHeight;
        if (top > cTop && top < cTop + cHeight) {
          this.swapIndex = i;
        }
      });
      animation = animation.map((item, i) => {
        let height = this.childStyle[this.index].height;
        if (this.index < this.swapIndex) {
          if (i > this.index && i <= this.swapIndex && this.swapIndex !== this.index) {
            const start = this.index + 1;
            const end = i;
            height = 0;
            this.childStyle.slice(start, end).forEach((_item) => {
              height += _item.height + _item.marginHeight;
            });
            return { top: this.childStyle[this.index].top + height };
          } if ((i > this.swapIndex || this.swapIndex === this.index) && i !== this.index) {
            return { top: this.childStyle[i].top };
          }
        } else if (this.index > this.swapIndex) {
          if (i < this.index && i >= this.swapIndex && this.swapIndex !== this.index) {
            height = this.childStyle[this.index].height + this.childStyle[this.index].marginHeight;
            return { top: this.childStyle[i].top + height };
          } if ((i < this.swapIndex || this.swapIndex === this.index) && i !== this.index) {
            return { top: this.childStyle[i].top };
          }
        }
        if (i !== this.index) {
          return { top: this.childStyle[i].top };
        }
        return item;
      });
    }
    this.setState({ childStyle, animation });
  };

  getChildren = (item, i) => {
    const onMouseDown = this.onMouseDown.bind(this, i);
    const style = { ...this.state.childStyle[i] };
    return React.createElement(
      TweenOne,
      {
        ...item.props,
        onMouseDown,
        onTouchStart: onMouseDown,
        style: { ...item.style, ...style },
        key: item.key,
        animation: this.state.animation[i],
        component: item.type,
        onMouseMove: this.onMouseMove,
        onTouchMove: this.onMouseMove,
        onMouseUp: this.onMouseUp,
        onTouchEnd: this.onMouseUp,
      }
    );
  };

  sortArray = (_array, nextNum, num) => {
    const current = _array[num];
    const array = _array.map((item) => item);
    array.splice(num, 1);
    array.splice(nextNum, 0, current);
    return array;
  };

  handleInputChange = (event) => {
    this.setState({ inputContent: event.target.value });
  }



  onInputEnter = (e) => {
    e.preventDefault();
    const { options, order } = this.state;
    const val = e.target.value;
    const dbref = this.props.firebase[this.props.refname](`${this.props.authuser.uid}/${this.props.childname}`);
    const maxindex = options.length ? options.length - 1 : -1;
    const newOption = { [maxindex + 1]: val };


    if (val.length > 0) {
      this.setState({ error: null });

      Promise.all([dbref.child('options')
        .update(newOption),
      dbref.child('order')
        .set([maxindex + 1, ...order])])
        // dbref.transaction(post => {
        //   if (post) {
        //   console.log(post);
        //   post.push(val);
        //   } else {
        //     console.log('no post');
        //     dbref.update(newOption);
        //   }})
        .catch(error => {
          console.log('update error', error);
          message.error(error.message, 2);
          this.setState({ error });
        }).then(() => {
          message.success("Edits saved");
          this.setState((state, props) => ({
            inputContent: '',
            order: [maxindex + 1, ...state.order],
            options: [...state.options, val],
            // selected: maxindex + 1,
            // radiovalue: maxindex + 1,
          }));

          // this.props.onClick()(val, maxindex + 1);  //this is to change the pomotimer name to the newest addition
          // listRef.current.appendToChildrenList(newChild);

        });
    }


  }


  onRadioChange = (e) => {
    e.preventDefault();
    if (this.state.editItem === null) {
      this.props.onClick()(this.state.options[e.target.value], e.target.value);
    }
  }


  render() {


    const dataArray = this.state.order.map(od => this.order2data(od, this.state.options));
    const childrenToRenderRaw = dataArray.map(item => this.data2child(item));

    const childrenToRender = [...childrenToRenderRaw].map(this.getChildren);

    const props = { ...this.props };
    [
      'component',
      'animType',
      'dragClassName',
      'appearAnim',
      'onEventChange',
    ].forEach((key) => delete props[key]);

    let listnode;

    if (this.props.appearAnim) {
      listnode = React.createElement(QueueAnim, {
        ...props,
        ...this.props.appearAnim,
        style: { ...this.state.style },
      }, childrenToRender);
    } else {
      listnode = React.createElement(this.props.component, {
        ...props,
        style: { ...this.state.style },
      }, childrenToRender)
    };




    return (<Fragment>

      <LimitInput
        value={this.state.inputContent}
        onChange={this.handleInputChange}
        // autoSize={{ minRows: 1 }}
        allowClear
        maxLength={this.props.maxcontentlength}
        placeholder={this.props.inputplaceholder}
        style={{ marginTop: 20, marginBottom: 10, marginRight: "auto" }}
        onPressEnter={this.onInputEnter}
      />


      {this.state.loading ?
        <Row type="flex" justify="center" style={{ marginTop: 40, marginBottom: 40 }}>
          <Spin size="large" />
        </Row> :


        <div className={`${className}-wrapper`} ref={this.parentRef}>

          <div className={className}>
            <Radio.Group onChange={this.onRadioChange} value={this.props.radiovalue}>
              {listnode}
            </Radio.Group>
          </div>

        </div>
      }


    </Fragment>



    )


  }

}

