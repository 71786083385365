// this is for redirecting to a checkout page, instead of building checkout elements native on your own page

import React from 'react';
// import { useHistory } from 'react-router-dom';

// import { loadStripe } from "@stripe/stripe-js";

import {
    Button, message
} from 'antd';


import { createPortalSession } from '../Functions';


import * as ROUTES from "../../constants/routes";


// const STRIPE_PUBLISHABLE = process.env.NODE_ENV === 'production'
//     ? 'pk_live_8WsaPkF64FHPbkohVhNyGib0'
//     : 'pk_test_51DtKnxDZtzhNcTwAsZYx3uetzsRVlBdtDqJI980flGs1Rbg7l4MP5M68r2V6jBlh68EGANzACQCwFYR8pjJPH6ch00XEaIHfey';


// const success_url = ROUTES.SUCCESS;

// const cancel_url = ROUTES.CANCELLED;

// const Checkout = ({ name, description, amount }) => <Elements stripe={stripePromise}>
//   <CheckoutForm name description amount currency />
// </Elements>

const App = (props) => {

    // const history = useHistory();

    // const [stripe, setStripe] = useState(null);
    // const [loading, setLoading] = useState(true);

    // console.log(props.authUser.email);

    const successSession = data => {
        message.success('Redirecting...', 1);

        console.log('data is', data);

        window.location.assign(data.data);

    };

    const errorSession = data => {
        message.error('Error. Please go back and try again.', 3);
        console.log(props);
        // props.history.push(ROUTES.PAYMENT_ERROR);
    };


    // useEffect(() => {

    //     loadStripe(STRIPE_PUBLISHABLE).then(st => {
    //         setStripe(st);
    //         setLoading(false);
    //     });

    // }, []);


    return <Button {...props.options}
        onClick={() => createPortalSession(props.authUser.stripeCusId, ROUTES.PROFILE, successSession, errorSession)}
    // onClick={() => getStripeCustomer(props.authUser.stripeCusId, successSession, errorSession)}
    >{props.children}</Button>
}

export default App;
