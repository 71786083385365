import axios from 'axios';
import { makeCancelable } from '../Hooks';


const produ = 'https://us-central1-secresilk.cloudfunctions.net/';

const testu = 'http://localhost:5001/secresilk/us-central1/';

const USER_CREATION_URL = process.env.NODE_ENV === 'production'
    ? produ + 'api/signup'
    : testu + 'api/signup';


const CAPTCHA_VERIFICATION_URL = process.env.NODE_ENV === 'production'
    ? produ + 'api/verify'
    : testu + 'api/verify';


const QUIZ_LOGGING_URL = process.env.NODE_ENV === 'production'
    ? produ + 'api/logquiz'
    : testu + 'api/logquiz';


const PAYMENT_SERVER_URL = process.env.NODE_ENV === 'production'
    ? produ + 'api/payment'
    : testu + 'api/payment';

const CHECKOUT_SERVER_URL = process.env.NODE_ENV === 'production'
    ? produ + 'api/create_checkout_session'
    : testu + 'create_checkout_session';

const PORTAL_SESSION_URL = process.env.NODE_ENV === 'production'
    ? produ + 'api/create_portal_session'
    : testu + 'api/create_portal_session';


const CUS_CREATION_SERVER_URL = process.env.NODE_ENV === 'production'
    ? produ + 'api/add_stripe_cus'
    : testu + 'api/add_stripe_cus';


const GET_CUSTOMER_URL = process.env.NODE_ENV === 'production'
    ? produ + 'api/get_customer'
    : testu + 'api/get_customer';


const WEBFORM_SUBMISSION_URL = process.env.NODE_ENV === 'production'
    ? produ + 'api/webform'
    : testu + 'api/webform';

const fromDollarToCent = amount => amount * 100;


export const createUser = (authuser, referrer, username, email) =>
    axios.post(USER_CREATION_URL,
        {
            uid: authuser.user.uid,
            referrer,
            username,
            email
        });



export const verifyCaptcha = (captchatoken) =>
    axios.post(CAPTCHA_VERIFICATION_URL,
        {
            captchatoken
        });



export const logQuiz = (data, uid) =>
    axios.post(QUIZ_LOGGING_URL, {
        data,
        uid
    });


export const onToken = (amount, description, currency, successPayment, errorPayment) => token =>
    axios.post(PAYMENT_SERVER_URL,
        {
            description,
            source: token.id,
            currency,
            amount: fromDollarToCent(amount)
        })
        .then(successPayment)
        .catch(errorPayment);


// export const createCheckoutSession0 = (success_url, cancel_url, successPayment, errorPayment) => axios.post(CHECKOUT_SERVER_URL,
//     {
//         success_url,
//         cancel_url
//         // source: token.id,
//         // currency,
//         // amount: fromDollarToCent(amount)
//     })
//     .then(successPayment)
//     .catch(errorPayment);


export const createCheckoutSession = async (uid, email, stripeCusId, level, successUrl, cancelUrl, successSession, errorSession) => {


    const wrappedPromise = makeCancelable(axios.post(CHECKOUT_SERVER_URL, { uid, email, stripeCusId, level, successUrl, cancelUrl }));

    wrappedPromise.promise.then(successSession)
        .catch(errorSession);

}

export const createPortalSession = async (stripeCusId, returnUrl, successSession, errorSession) => {


    const wrappedPromise = makeCancelable(axios.post(PORTAL_SESSION_URL, { stripeCusId, returnUrl }));

    wrappedPromise.promise.then(successSession)
        .catch(errorSession);

}

export const getStripeCustomer = async (stripeCusId, successSession, errorSession) => {


    const wrappedPromise = makeCancelable(axios.post(GET_CUSTOMER_URL, { stripeCusId }));

    wrappedPromise.promise.then(successSession)
        .catch(errorSession);

}



export const createStripeCus = async (firebase, email, uid, success, error) => {

    console.log(firebase);

    const token = await firebase.auth.currentUser.getIdToken();

    const headers = {
        Authorization: 'Bearer ' + token,
    };

    const wrappedPromise = makeCancelable(axios.post(CUS_CREATION_SERVER_URL, { email, uid }, { headers: headers }));

    wrappedPromise.promise.then(success)
        .catch(error);

}



export const submitWebform = async (formdata, success, error) => {


    const wrappedPromise = makeCancelable(axios.post(WEBFORM_SUBMISSION_URL,
        {
            formdata
        }));

    wrappedPromise.promise.then(success)
        .catch(error);

}


