// this file has minor differences from the one in Author module

import React, { useRef, useState, Fragment, cloneElement } from 'react';
import { Input } from "antd";
import IdleTimer from 'react-idle-timer';
// import { convertRawToHTML, convertEditorStateToRaw, convertRawToEditorState, convertEditorStateToHTML } from 'braft-convert';

import { useInterval } from '../Hooks';

const { TextArea } = Input;


function IntervalCounter(props) {
    let [count, setCount] = useState(0);

    useInterval(() => {
        props.onInterval();
        setCount(count + 1);
    }, props.isRunning ? props.interval : null);
    return null;

    // return <h1>{count}</h1>;
}

// see https://stackoverflow.com/questions/27864951/how-to-access-childs-state-in-react
const AutosaveEditor = (props) => {

    const idletimer = useRef(null)

    // const onIdle = () => {
    //     const contentObject = convertEditorStateToRaw(props.editorState);
    //     const texts = contentObject.blocks.map(b => b.text).join(' ');
    //     const newlen = texts.length;
    //     if (newlen > 0) {
    //         // submitContent();
    //         props.doSubmit();
    //     }
    //     console.log("is idle now");
    //     props.SetIsIntervalCounterRunning(false);
    // }


    const onActive = () => {
        props.SetIsIntervalCounterRunning(true);
    }



    return (
        <Fragment>
            <IntervalCounter
                onInterval={props.autoSubmit}
                interval={props.interval}
                isRunning={props.isIntervalCounterRunning}
            />
            <IdleTimer
                ref={idletimer}
                element={document}
                onActive={onActive}
                onIdle={props.onIdle}
                //   onAction={onAction}
                debounce={250}
                timeout={1000 * 60 * 2} />

            {/* {props.children} */}
            {/* Note this only accepts one single child */}
            {cloneElement(props.children, { editorPlaceholder: props.editorPlaceholder })}


        </Fragment>
    )

};




const TextAreaWithoutButton = (props) => (
    <TextArea
        value={props.editorState}
        onChange={props.handleEditorChange}
        autoSize={{ minRows: 3 }}
        allowClear
        maxLength={500}
        placeholder={props.editorPlaceholder}
    />
);



export { AutosaveEditor, TextAreaWithoutButton };


