import React from "react";

import {
    Card,
    Row,
    Typography,
    Avatar
} from "antd";


import moment from 'moment';

const { Title } = Typography;

const { Meta } = Card;




const App = (props) => {


    return <Card style={{ width: "100%", marginTop: 20 }}>
        <Meta
            avatar={
                <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
            }
            title={<Title level={2}>{props.authUser.username}</Title>}
            description={props.authUser.email}
        />

        <Row type="flex" justify="start" gutter={0} style={{ margin: 20 }}>
            <Title level={5}>
                {(props.authUser.level === "2" ? 'Premium Member' : 'Member') + ' of Petspoke'}
            </Title>
        </Row>

        <Row type="flex" justify="start" gutter={0} style={{ margin: 20 }}>
            <Title level={5}>Joined Petspoke on {moment(props.authUser.created).format("MMMM D, YYYY")}</Title>
        </Row>


        <Row type="flex" justify="start" gutter={0} style={{ margin: 20 }}>

            <Title level={5}>Number of friends referred: {props.authUser.referral_count || 0}</Title>
        </Row>







    </Card>
}





export default App;

