import React from 'react';
import ReactDOM from 'react-dom';
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';
// import PropTypes from 'prop-types';




// const className = 'list-sort-demo';






export default class ListSort extends React.Component {

  //   static propTypes = {
  //     component: PropTypes.any,
  //     animType: PropTypes.string,
  //     onChange: PropTypes.any,
  //     dragClassName: PropTypes.string,
  //     appearAnim: PropTypes.object,
  //     onEventChange: PropTypes.any,
  //   };

  static defaultProps = {
    component: 'div',
    animType: 'y',
    onChange: () => {
    },
    dragClassName: null,
    onEventChange: () => {
    },
    appearAnim: null,
  };

  constructor(props) {
    super(props);
    // this.parentRef = createRef();
    this.state = {

      children: [],
      style: {},
      childStyle: [],
      animation: [],
      error: null,
      //   editItem: null,
      //   itemloading: null,
      //   isDragging: false,
      //   completed: [],
      // sliderPos: 0,
      node: null,
    };
    this.index = null;
    this.swapIndex = null;
    this.mouseXY = null;
    this.childStyle = [];
    this.children = [];
    // this.isDragged = false;
  }




  pendingPromises = [];

  componentWillUnmount = () =>
    this.pendingPromises.map(p => p.cancel());

  appendPendingPromise = promise =>
    this.pendingPromises = [...this.pendingPromises, promise];

  removePendingPromise = promise =>
    this.pendingPromises = this.pendingPromises.filter(p => p !== promise);

  componentDidMount() {

    this.node = ReactDOM.findDOMNode(this);

  }


  onMouseDown = (i, e) => {

    if (this.props.isDragging) {
      return;
    }
    // this.setState({ editItem: null });
    // console.log(this.node);
    const rect = this.node.getBoundingClientRect();
    // const rect = this.node.current.offsetHeight || 0;
    document.body.style.overflow = 'hidden';
    this.props.onEventChange(e, 'down');
    const style = {
      height: `${rect.height}px`,
      // height: `${rect}px`,
      userSelect: 'none',
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      MsUserSelect: 'none',
    };

    this.children = [...this.node.children];
    // console.log(this.children);
    this.childStyle = [];
    const childStyle = this.children.map((item, ii) => {
      const cItem = this.children[ii + 1];
      let marginHeight;
      let marginWidth;
      if (cItem) {
        marginHeight = cItem.offsetTop - item.offsetTop - item.clientHeight;
        marginWidth = cItem.offsetLeft - item.offsetLeft - item.clientWidth;
      } else {
        const parentHeight = item.parentNode.clientHeight
          - parseFloat(getComputedStyle(item.parentNode).getPropertyValue('padding-bottom'));
        const parentWidth = item.parentNode.clientWidth
          - parseFloat(getComputedStyle(item.parentNode).getPropertyValue('padding-right'));
        marginHeight = parentHeight - item.offsetTop - item.clientHeight;
        marginWidth = parentWidth - item.offsetLeft - item.clientWidth;
      }
      const d = {
        width: item.clientWidth,
        height: item.clientHeight,
        top: item.offsetTop,
        left: item.offsetLeft,
        margin: 'auto',
        marginHeight,
        marginWidth,
        position: 'absolute',
        zIndex: ii === i ? 1 : 0,
      };
      this.childStyle.push({ ...d });

      // console.log(this.childStyle);
      return d;
    });
    // this.childStyle = childStyle;
    const animation = this.children.map((item, ii) => (i === ii && (!this.props.dragClassName
      ? { scale: 1.2, boxShadow: '0 10px 10px rgba(0,0,0,0.15)', duration: 200 } : null)) || null);
    this.index = i;
    this.swapIndex = i;
    this.mouseXY = {
      startX: e.touches === undefined ? e.clientX : e.touches[0].clientX,
      startY: e.touches === undefined ? e.clientY : e.touches[0].clientY,
      top: childStyle[i].top,
      left: childStyle[i].left,
    };
    if (this.props.dragClassName) {
      this.listDom = e.currentTarget;
      this.listDom.className = `${this.listDom.className
        .replace(this.props.dragClassName, '').trim()} ${this.props.dragClassName}`;
    }
    // this.isDragged = true;
    this.setState({
      style,
      childStyle,
      animation,
    });
  };



  onMouseUp = (e) => {
    if (!this.mouseXY) {
      return;
    }
    this.mouseXY = null;
    document.body.style.overflow = null;
    this.props.onEventChange(e, 'up');
    const animation = this.state.animation.map((item, i) => {
      if (this.index === i) {
        const animate = { duration: 500 };
        // let height = 0;

        if (this.props.animType === 'y') {
          // console.log('anim type is y');
          if (this.swapIndex > this.index) {
            // console.log('swapindex larger', this.childStyle[this.swapIndex].top);
            // const start = this.index + 1;
            // const end = this.swapIndex + 1;
            // this.childStyle.slice(start, end).forEach((_item) => {
            //   console.log(_item.height);
            //   height += _item.height + _item.marginHeight;
            // });
            // animate.top = height + this.childStyle[this.index].top;
            animate.top = this.childStyle[this.swapIndex].top;
          } else {
            // console.log('swapindex not larger', this.childStyle[this.swapIndex].top);    //this appears to calculate the landing top position so as to smooth the landing
            animate.top = this.childStyle[this.swapIndex].top;
          }
        }

        const dragScale = !this.props.dragClassName
          && ({
            scale: 1,
            boxShadow: '0 0px 0px rgba(0,0,0,0)',
          });

        return {
          ...dragScale,
          ...animate,
          onComplete: () => {

            const callbackBool = this.index !== this.swapIndex;
            this.childStyle = [];
            if (callbackBool) {
              this.props.handleOrder(this.sortArray(this.props.order, this.swapIndex, this.index));
            }

            this.props.setIsDragging(false);

            this.setState((state, props) => ({
              style: {},
              childStyle: [],
              animation: [],
              itemloading: false,
              //   isDragging: false,
            }), () => {

            });

            this.index = null;
            this.swapIndex = null;
          },
        };
      }
      return item;
    });
    if (this.props.dragClassName) {
      this.listDom.className = `${this.listDom.className
        .replace(this.props.dragClassName, '').trim()}`;
    }
    this.setState({ animation });
  };



  onMouseMove = (e) => {
    if (!this.mouseXY) {
      return;
    }
    // this.setState({ isDragging: true, editItem: null, completed: []});
    this.props.handleMouseMove();
    this.mouseXY.x = e.touches === undefined ? e.clientX : e.touches[0].clientX;
    this.mouseXY.y = e.touches === undefined ? e.clientY : e.touches[0].clientY;
    const childStyle = this.state.childStyle;
    let animation = this.state.animation;


    if (this.props.animType === 'x') {
      childStyle[this.index].left = this.mouseXY.x - this.mouseXY.startX + this.mouseXY.left;
    } else {
      childStyle[this.index].top = this.mouseXY.y - this.mouseXY.startY + this.mouseXY.top;
      this.swapIndex = childStyle[this.index].top < this.childStyle[this.index].top
        ? 0 : this.index;
      this.swapIndex = childStyle[this.index].top
        > this.childStyle[this.index].top + this.childStyle[this.index].height
        ? childStyle.length - 1 : this.swapIndex;

      const top = childStyle[this.index].top;
      this.childStyle.forEach((item, i) => {
        const cTop = item.top;
        const cHeight = item.height + item.marginHeight;
        if (top > cTop && top < cTop + cHeight) {
          this.swapIndex = i;
        }
      });
      animation = animation.map((item, i) => {
        let height = this.childStyle[this.index].height;
        if (this.index < this.swapIndex) {
          if (i > this.index && i <= this.swapIndex && this.swapIndex !== this.index) {
            const start = this.index + 1;
            const end = i;
            height = 0;
            this.childStyle.slice(start, end).forEach((_item) => {
              height += _item.height + _item.marginHeight;
            });
            return { top: this.childStyle[this.index].top + height };
          } if ((i > this.swapIndex || this.swapIndex === this.index) && i !== this.index) {
            return { top: this.childStyle[i].top };
          }
        } else if (this.index > this.swapIndex) {
          if (i < this.index && i >= this.swapIndex && this.swapIndex !== this.index) {
            height = this.childStyle[this.index].height + this.childStyle[this.index].marginHeight;
            return { top: this.childStyle[i].top + height };
          } if ((i < this.swapIndex || this.swapIndex === this.index) && i !== this.index) {
            return { top: this.childStyle[i].top };
          }
        }
        if (i !== this.index) {
          return { top: this.childStyle[i].top };
        }
        return item;
      });
    }
    this.setState({ childStyle, animation });
  };

  getChildren = (item, i) => {
    const onMouseDown = this.onMouseDown.bind(this, i);
    const style = { ...this.state.childStyle[i] };
    return React.createElement(
      TweenOne,
      {
        ...item.props,
        onMouseDown,
        onTouchStart: onMouseDown,
        style: { ...item.style, ...style },
        key: item.key,
        animation: this.state.animation[i],
        component: item.type,
        onMouseMove: this.onMouseMove,
        onTouchMove: this.onMouseMove,
        onMouseUp: this.onMouseUp,
        onTouchEnd: this.onMouseUp,
      }
    );
  };

  sortArray = (_array, nextNum, num) => {
    const current = _array[num];
    // const array = _array.map((item) => item);
    const array = [..._array];
    array.splice(num, 1);  //remove 1 element at num
    array.splice(nextNum, 0, current);  //insert element "current" at nextNum
    return array;
  };




  render() {


    const childrenToRender = [...this.props.childrenToRenderRaw].map(this.getChildren);

    return (

      <QueueAnim
        {...this.props.appearAnim}
        style={{ ...this.state.style }}
      // className={this.props.isDragging ? 'class-while-dragging' : 'class-before-dragging'}
      >
        {childrenToRender}
      </QueueAnim>

    )


  }

}

