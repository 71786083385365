import React, { Component } from 'react';

import {
    message
} from 'antd';



import Contact from './contact';



class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,
            visible: true,
            dbLoading: false,
            pushkey: null,
            data: {},
            adding: false,
        };

        this.maxShortnameLength = 18;

        this.maxNameLength = 100;


    }

    pendingPromises = [];



    componentWillUnmount = () =>
        this.pendingPromises.map(p => p.cancel());

    appendPendingPromise = promise =>
        this.pendingPromises = [...this.pendingPromises, promise];

    removePendingPromise = promise =>
        this.pendingPromises = this.pendingPromises.filter(p => p !== promise);



    // componentDidMount = () => {

    //     const query = this.props.firebase.db.ref(`${this.props.dbrefname}/${this.props.authUser.uid}`);

    //     const wrappedPromise = makeCancelable(query.once("value"));
    //     this.appendPendingPromise(wrappedPromise);
    //     this.setState({ loading: true });

    //     wrappedPromise.promise
    //         .then(snapshot => snapshot.val())
    //         .then(rawdata => {
    //             if (Object.keys(rawdata).length > 0) {
    //                 this.setState({ data: rawdata, loading: false });
    //                 this.removePendingPromise(wrappedPromise);
    //             }
    //         })
    //         .catch(errorInfo => {
    //             if (!errorInfo.isCanceled) {
    //                 this.setState({ error: errorInfo.error, loading: false });
    //                 this.removePendingPromise(wrappedPromise);
    //             }
    //         });

    // }







    render() {


        return (


            <div style={{ margin: 20 }}>

                <Contact {...this.props} />


            </div>



        );
    }
}




export default App;

