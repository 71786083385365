import React, { useState, Fragment } from "react";

import {
    Row,
    Col,
    Typography,
    Button,
    List,
    Popover,
    Checkbox
    // Tag
} from "antd";

import { level2amount } from "../../constants";
import Checkout from '../CheckOut';

const { Title, Paragraph } = Typography;





const App = (props) => {

    const [visible, setVisible] = useState(false);
    const [checked, setChecked] = useState(false);

    // const onClickGet = (le) => {
    //     setLevel(le);
    //     setStep(1);
    // }

    const onCheckChange = e => {
        e.preventDefault();
        setChecked(e.target.checked);
    }

    const calFee = am =>
        Math.ceil(((am + 0.3) / (1 - .029) - am) * 10) / 10;


    const feeList = le => [
        { name: 'Subtotal', des: 'the price of the membership', amount: le && ('$' + level2amount[le].toString()) },
        {
            name: 'Transaction Fee', des: 'third-party fee to process payments',
            amount: le && '$' + calFee(level2amount[le]).toString()
        },
        // { name: 'Total', des: 'total payment amount', amount: le && '$' + (calFee(level2amount[le]) + level2amount[le]).toString() }
    ];


    const step1 = <Fragment>
        < Row type="flex" justify="center" style={{ margin: 60 }}>
            <List
                style={{ minWidth: 400, maxWidth: 800 }}
                itemLayout="horizontal"
                dataSource={feeList(props.level)}
                header={<Title level={4} style={{ marginBottom: 20 }}>{props.level === 1 ? 'Standard Membership' : 'Premium Membership'}</Title>}
                footer={<Title level={5} style={{ marginBottom: 20, float: "right" }}>
                    Total:&nbsp;&nbsp;&nbsp;{props.level && '$' + (calFee(level2amount[props.level]) + level2amount[props.level]).toString()}</Title>}
                renderItem={item => (
                    <List.Item
                    >
                        <List.Item.Meta
                            title={item.name}
                            description={item.des}
                        />
                        <div>{item.amount}</div>
                    </List.Item>
                )}
            />
        </Row>
        <Row type="flex" justify="center" style={{ marginTop: 30 }} gutter={24}>
            <Col span={4}>
                <Button block onClick={() => props.setStep(0)}>Back</Button>
            </Col>
            <Col span={4}>
                {/* <Button block type="primary" onClick={ } disabled={!amount || !selected}>Next</Button> */}
                <Popover
                    content={<div style={{ margin: 12, maxWidth: 800 }}>
                        <Typography>
                            <Paragraph>
                                You are about to be redirected to Stripe to continue with the payment.
                            </Paragraph>
                            {/* <Row type="flex" justify="center" style={{ margin: 20 }}>
            <Alert message={<span>We don't store your payment details. Data and communications are encrypted for all transactions.
              For more information see the <a href='https://stripe.com/docs/security/stripe'> Security </a> and
              <a href='https://stripe.com/privacy'> Privacy </a> pages at Stripe, the market-leading transaction provider.</span>} type="success" />
          </Row> */}
                            <Paragraph>
                                We don't store your payment details. Data and communications are encrypted for all transactions.
                                For more information see the <a href='https://stripe.com/docs/security/stripe'> Security </a> and
                                <a href='https://stripe.com/privacy'> Privacy </a> pages at Stripe, the market-leading transaction provider.
                            </Paragraph>

                            <Paragraph>
                                After the payment, you'll be redirected back to Petspoke.
                            </Paragraph>

                            <Paragraph>
                                Your membership is <b>NOT</b> refundable. If you cancel an existing subscription to membership,
                                there is no refund but you'll not be charged after the current billing period.
                                It doesn't affect the existing subscription, and your membership will remain active until the billing period ends.
                            </Paragraph>
                        </Typography>
                        <Row type="flex" justify="center" style={{ marginTop: 30 }} gutter={24}>
                            <Checkbox checked={checked} onChange={onCheckChange}>I accept the above and proceed to payment</Checkbox>
                        </Row>
                        <Row type="flex" justify="center" style={{ marginTop: 30 }} gutter={24}>
                            <Col span={4}>
                                <Button block onClick={() => setVisible(false)}>Cancel</Button>
                            </Col>
                            <Col span={4}>
                                {/* <Button type="primary" block onClick={() => setStep(2)} disabled={!checked}>OK</Button> */}
                                <Checkout
                                    options={{ type: "primary", block: true, disabled: !checked }}
                                    children="OK"
                                    level={props.level}
                                    {...props} />
                            </Col>
                        </Row>


                    </div>
                    }
                    title={<Title level={5}>Continue with Payment?</Title>}
                    trigger="click"
                    visible={visible}
                // onVisibleChange={handleVisibleChange}
                >
                    <Button block type="primary" onClick={() => setVisible(true)}>
                        Next
                    </Button>
                </Popover>
            </Col>
        </Row >
    </Fragment >;




    return (
        <Fragment>
            {step1}

        </Fragment>

    )
}


export default App;

