import React, { Fragment, Component } from 'react';

import {
    message, Row, Layout, Button, Popover, Input, AutoComplete
} from 'antd';


import moment from 'moment';
import elasticlunr from "elasticlunr";

import { makeCancelable } from '../Hooks';
// import PostModal from './modal-post';
import PostTree from './tree-post';
import { EditorScreen } from "../Editor";
import TitleInput from './title-input';



const { Sider, Content } = Layout;
const { Search } = Input;


class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            // visible: true,
            dbLoading: false,
            // pushkey: null,
            titledata: {},
            treeData: [],
            selectedLeaves: [],
            createVisible: false,
            expandedKeys: [],
            loadedKeys: [],
            titleHits: [],
            titleSearchInput: '',
            // titleIdx: null,
            // allnodesdata:{},
        };

        this.maxTitleLength = 100;
        this.allnodesdata = {};

        this.treeRef = React.createRef();
        this.editorRef = React.createRef();

        this.overallRef = React.createRef();

        this.titleIdx = null;

    }

    checkIfMounted() {
        return this.overallRef.current != null;
    }


    pendingPromises = [];


    componentWillUnmount = () =>
        this.pendingPromises.map(p => p.cancel());

    appendPendingPromise = promise =>
        this.pendingPromises = [...this.pendingPromises, promise];

    removePendingPromise = promise =>
        this.pendingPromises = this.pendingPromises.filter(p => p !== promise);



    componentDidMount = () => {

        const query = this.props.firebase.db.ref(`usertitles/${this.props.authUser.uid}`);
        const query2 = this.props.firebase.db.ref(`${this.props.dbrefname}/${this.props.authUser.uid}`).orderByChild('parent').equalTo(null);

        const wrappedPromise = makeCancelable(query.once("value"));
        const wrappedPromise2 = makeCancelable(query2.once("value"));

        this.appendPendingPromise(wrappedPromise);
        this.appendPendingPromise(wrappedPromise2);


        Promise.all([wrappedPromise.promise, wrappedPromise2.promise])
            .then(snapshots => snapshots.map(s => s.val()))
            .then(rawdata => {
                if (Object.keys(rawdata[0]).length > 0) {
                    this.setState({ titledata: rawdata[0] });

                } else {
                    this.setState({ loading: false });
                }

                if (Object.keys(rawdata[1]).length > 0) {

                    const arrayed1sttreedata = Object.entries(rawdata[1])
                        .map(([k, v], i) => {
                            const childrenids = (v.children && Object.keys(v.children)) || [];
                            const result = childrenids.length > 0 ? { title: v.name, key: k, childrenids } : { title: v.name, key: k, isLeaf: true };
                            return result
                        });
                    this.setState({ treeData: arrayed1sttreedata });

                }

                this.removePendingPromise(wrappedPromise);
                this.removePendingPromise(wrappedPromise2);

            })
            // .then(() => this.setState({ loading: false }))
            .catch(errorInfo => {
                if (!errorInfo.isCanceled) {
                    this.setState({ error: errorInfo.error, loading: false });
                    this.removePendingPromise(wrappedPromise);
                    this.removePendingPromise(wrappedPromise2);
                }
            });

    }




    componentDidUpdate(prevProps, prevState) {

        //expensive computation of the pages of texts. With react functional component you can use useMemo. 
        //with class component, you can use ComponentDidUpdate

        if (prevState.titledata !== this.state.titledata) {

            const updating = () => {


                const titles = Object.entries(this.state.titledata).map(([k, v], i) => ({
                    key: k, title: v
                }));

                const ix = elasticlunr(function () {
                    this.addField("title");
                    this.setRef('key');

                    titles.forEach((p) => {
                        this.addDoc(p);
                    });
                });

                this.titleIdx = ix;

                if (this.checkIfMounted()) {
                    this.setState({ loading: false });
                }

            }


            if (this.checkIfMounted()) {
                updating();
            }



        }

    }







    handleVisibilityChange = () => {
        // setCreateVisible(!createVisible);
        this.setState(state => ({ createVisible: !state.createVisible }));

    }



    // const removeFromDb = (uid, label, refname) => {
    //     const ref = props.firebase[refname];
    //     return ref(uid).child(label)
    //         .remove()
    //         .catch(error => {
    //             // this.setState({ error })
    //         });
    // }




    onSelectTocLeaf = async (keys) => {
        // console.log(editorRef.current);
        if (this.editorRef.current) { await this.editorRef.current.savePost(); }
        // setSelectedLeaves(keys);
        this.setState({ selectedLeaves: keys });
    }



    addNodeData = (id, v) => {
        // console.log(id, v);
        this.allnodesdata = { ...this.allnodesdata, [id]: v };

    }



    saveValue2Db = (id, newdata) => {

        const dbref = this.props.firebase.db.ref(`${this.props.dbrefname}/${this.props.authUser.uid}/${id}`);

        dbref.update(newdata)
            .catch(error => {
                // this.setState({ error });
                console.log('update error', error);
                message.error(error.message, 2);
            }).then(() => {
                message.success("Edits saved");
            });

    }


    onSibling = (selfkey, siblingkey) => {
        const parent = (this.allnodesdata[siblingkey] && this.allnodesdata[siblingkey].parent) || null;
        this.saveValue2Db(selfkey, { parent });
    }

    onParent = (selfkey, parentkey) => {
        this.saveValue2Db(selfkey, { parent: parentkey });
    }


    updateTitle = (val, key) => {

        const updateNodeTitle = (node) => {
            node.title = val;
        }

        this.setState(state => {
            const oldTreeCopy = [...state.treeData];
            this.findChildDo(key, oldTreeCopy, updateNodeTitle);
            console.log('old tree is now', oldTreeCopy);
            return { treeData: oldTreeCopy };
        });

    }


    onTitlePressEnter = (val, leafId) => {

        this.setState({ titledata: { ...this.state.titledata, [leafId]: val } });


        this.updateTitle(val, leafId);

        const dbref = this.props.firebase.db.ref(`${this.props.dbrefname}/${this.props.authUser.uid}/${leafId}`);

        dbref.update({ name: val })
            .catch(error => {
                // this.setState({ error });
                console.log('update error', error);
                // message.error(error.message, 2);
            }).then(() => {
                message.success("Title saved");
                // console.log('new treedata is now,', this.state.treeData);
            });

    }


    addChild = (newchild, key) => {

        if (key === null) {
            this.setState(state => ({ treeData: [newchild, ...state.treeData] }));
            // props.setTreeData(oldTree => [newchild, ...oldTree]);
            return;
        }

        const addNodeChild = (node) => {
            node.children = node.children ? [newchild, ...node.children] : [newchild];
            node.isLeaf = false;
        }

        const updateKeys = lk => {
            if (!lk.includes(key)) { return [...lk, key] } else { return lk };
        }


        this.setState(state => {
            const oldTreeCopy = [...state.treeData];
            this.findChildDo(key, oldTreeCopy, addNodeChild);
            return {
                treeData: oldTreeCopy,
                loadedKeys: updateKeys(state.loadedKeys),
                expandedKeys: updateKeys(state.expandedKeys)
            };
        });

        // setLoadedKeys(lk => { if (!lk.includes(key)) { return [...lk, key] } else { return lk } });
        // setExpandedKeys(lk => { if (!lk.includes(key)) { return [...lk, key] } else { return lk } });
        // console.log('loaded', loadedKeys, 'expanded', expandedKeys);

    }




    handleChildCreate = (name, parentid = null) => {

        const newname = name.trim();

        const dbref = this.props.firebase.db.ref(`${this.props.dbrefname}/${this.props.authUser.uid}`);

        const submitTime = Math.round(moment().format('X'));

        const newdata = {
            createTime: submitTime,
            name: newname,
            parent: parentid,
        };

        const wrappedPromise = makeCancelable(dbref.push(newdata));

        this.appendPendingPromise(wrappedPromise);

        wrappedPromise.promise
            .then(snap => {
                const key = snap.key;
                this.setState({ selectedLeaves: [key], titledata: { ...this.state.titledata, [key]: newname } });
                // setSelectedLeaves([key]);
                const newchild = { title: newname, key, isLeaf: true };
                this.addChild(newchild, parentid);
                // this.treeRef.current.doAddChild(newchild, parentid);
                // this.treeRef.current.doUpdateSelected([key]);
                // setTitledata({ ...titledata, [key]: newname });
                this.removePendingPromise(wrappedPromise);
                message.success("Created a new post");
            })
            .catch(errorInfo => {
                if (!errorInfo.isCanceled) {
                    // this.setState({ error: errorInfo.error, dbLoading: false });
                    console.log('push error', errorInfo.error);
                    this.removePendingPromise(wrappedPromise);
                    message.error(errorInfo.message, 2);
                } else {
                    console.log("data fetch is cancelled");
                }
            });


    }


    handleCreate = async (name, parentid = null) => {
        await this.handleChildCreate(name, parentid);
        // setCreateVisible(false);
        this.setState({ createVisible: false })
    }


    // const handleCreate = (name) => {
    //     const dbref = this.props.firebase.db.ref(`${this.props.dbrefname}/${this.props.authUser.uid}`);
    //     const submitTime = Math.round(moment().format('X'));

    //     const newdata = {
    //         createTime: submitTime,
    //         name: name.trim(),
    //     };

    //     const wrappedPromise = makeCancelable(dbref.push(newdata));

    //     this.setState({
    //         dbLoading: true,
    //     });

    //     wrappedPromise.promise
    //         .then(snap => {
    //             this.pushkey = snap.key;
    //             this.setState({
    //                 dbLoading: false,
    //                 tableData: [...this.state.tableData, { ...newdata, id: snap.key }],
    //                 rawdata: { ...this.state.rawdata, [snap.key]: newdata },
    //                 titledata: { ...this.state.titledata, [snap.key]: name.trim() },
    //                 visible: true,
    //             });
    //             this.removePendingPromise(wrappedPromise);
    //             message.success("Created a new post");
    //         })
    //         .catch(errorInfo => {
    //             if (!errorInfo.isCanceled) {
    //                 this.setState({ error: errorInfo.error, dbLoading: false });
    //                 console.log('push error', errorInfo.error);
    //                 this.removePendingPromise(wrappedPromise);
    //                 message.error(errorInfo.message, 2);
    //             } else {
    //                 console.log("data fetch is cancelled");
    //             }
    //         });

    // }

    // handleEdit = (itemkey) => {
    //     this.pushkey = itemkey;
    //     // this.props.history.push(ROUTES.LANDING+);
    //     this.setState({ dbLoading: true, visible: true });

    // }

    handleDelete = (itemkey) => {

        // console.log(this.state.titledata);
        // console.log('treeData is', this.state.treeData);

        const dbref = this.props.firebase.db.ref(`${this.props.dbrefname}/${this.props.authUser.uid}/${itemkey}`);
        const wrappedPromise = makeCancelable(dbref.remove());
        this.appendPendingPromise(wrappedPromise);
        const { [itemkey]: thistitle, ...othertitles } = this.state.titledata;


        wrappedPromise.promise
            .then(() => {

                this.setState(state => {
                    const oldTreeCopy = [...state.treeData];
                    this.removeChild(itemkey, oldTreeCopy);
                    // console.log('the new tree data is', oldTreeCopy);
                    return {
                        dbLoading: true,
                        titledata: othertitles,
                        treeData: oldTreeCopy,
                        loadedKeys: state.loadedKeys.filter(k => k !== itemkey),
                        expandedKeys: state.expandedKeys.filter(k => k !== itemkey)
                    };
                });

                this.removePendingPromise(wrappedPromise);

            })
            .catch(errorInfo => {
                if (!errorInfo.isCanceled) {
                    this.setState({ error: errorInfo.error });
                    this.removePendingPromise(wrappedPromise);
                } else {
                    console.log("deletion is cancelled");
                }
            });
        this.setState({ dbLoading: false });

    }




    findChildDo = (key, tree, somefunction) => {
        // let parentKey;
        // console.log('in findChildDo, key is', key, 'and current tree is', tree, 'with length', tree.length);

        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            // console.log('current node is,', node.key);
            if (node.key === key) {
                // console.log('found, in findChildDo');
                somefunction(node);
                return;
            }
            if (node.children) {
                // console.log('going to node.children, in findChildDo');
                // if (node.children.some(item => item.key === key)) {
                //     parentKey = node.key;
                //     somefunction()
                // } else if (getMatchingChild(key, node.children)) {
                //     parentKey = getParentKey(key, node.children);
                // }

                this.findChildDo(key, node.children, somefunction);
            }
        }
        // return parentKey;
    };



    removeChild = (key, tree) => {


        for (let i = 0; i < tree.length; i++) {
            // console.log('i is', i);
            const node = tree[i];
            // console.log('current node is,', node.key);
            if (node.key === key) {
                // console.log('found, in findChildDo');
                // somefunction(node);
                tree.splice(i, 1);
                return;
            }
            if (node.children) {
                const foundChildIdx = node.children.find(t => t.key === key);
                if (foundChildIdx !== -1) {
                    node.children = node.children.filter(t => t.key !== key);
                    if (node.children.length === 0) {
                        const { children, isLeaf, ...others } = node;
                        tree[i] = { ...others, isLeaf: true };
                    }
                } else {
                    this.removeChild(key, node.children);
                }

            }
        }
    }



    onTitleSearch = value => {

        const searchval = value.trim();

        if (searchval.length >= 3) {

            const results = this.titleIdx && this.titleIdx.search(searchval);
            const keys = results.map(r => r.ref);

            if (keys.length > 0) {
                this.setState({
                    titleHits: keys.map(k => ({ value: k, label: this.state.titledata[k] }))
                });
            }
        } else {
            this.setState({ titleHits: [] });
        }

    }


    onTitleSearchSelect = value => {

        this.onSelectTocLeaf([value]);
        this.setState({ titleHits: [], titleSearchInput: '' });
    }


    onTitleSearchChange = value => {
        this.setState({ titleSearchInput: value })
    }



    render() {

        const {
            // tableData,
            titledata,
            treeData,
            selectedLeaves,
            createVisible,
            loading,
            expandedKeys,
            loadedKeys,
            titleHits,
            titleSearchInput
        } = this.state;


        // console.log(treeData);



        return (<div ref={this.overallRef}>< Layout



            style={{
                overflow: 'auto',
                height: '100vh',

            }}>





            {/* <Skeleton active loading={loading} title={{ width: "20%" }} paragraph={{ rows: 1, width: Array(1).fill(400) }} round> */}

            {/* {visible ?
            <PostModal {...this.props}
                // visible={visible}
                visible={true}
                handleCancel={this.handleCancel}
                // handleSave={this.handleSave}
                // onSelect={this.onSelect}
                // onDeselect={this.onDeselect}
                pushkey={this.pushkey}
                // data={data}
                maxNameLength={this.maxNameLength}
                maxShortnameLength={this.maxShortnameLength}
                // saveValue={this.saveValue}
                // saveNewListValue={this.saveNewListValue}
                data={this.allnodesdata[this.pushkey]}
                firstleveldata={arrayed1stdata}
                titledata={titledata}
                onFinish={this.onFinish}
            /> : null} */}






            {/* <Anchor> */}


            < Sider
                breakpoint="lg"
                // collapsedWidth={80}
                onBreakpoint={broken => {
                    // console.log(broken);

                    // if (broken) {
                    //     setCollapsed(true);
                    // } else {
                    //     setCollapsed(false);
                    // }

                }
                }
                width="25%"
                // collapsible
                // collapsed={collapsed}
                // onCollapse={onCollapse}
                // style={{
                //     maxHeight: "none",
                //     overflow: 'auto',
                //     height: '100%',
                //     // position: 'fixed',
                //     left: 0
                // }}

                style={{
                    overflow: 'auto',
                    height: 'calc(100vh - 65px)',
                    position: 'fixed',
                    left: 0,
                }}
            >

                <Row type="flex" justify="center" style={{ margin: 20 }}>
                    <AutoComplete
                        defaultOpen={false}
                        style={{ width: "100%" }}
                        options={titleHits}
                        onSelect={this.onTitleSearchSelect}
                        onSearch={this.onTitleSearch}
                        notFoundContent={null}
                        value={titleSearchInput}
                        onChange={this.onTitleSearchChange}

                    >
                        {/* <AutoComplete
                showSearch
                value={props.value}
                defaultOpen={false}
                // mode="single"
                // tagRender={tagRender}
                // autoClearSearchValue={true}
                style={{ marginTop: 20, marginBottom: 10, margin: "auto" }}
                className='input-colon-selector'
                placeholder={props.placeholder}
                notFoundContent={null}    // this prevents showing the "no data" dropdown
                onChange={props.onChange}
                onSelect={onSelect}
                onSearch={onSearch}
                onFocus={onFocus}
                onBlur={onBlur}
                // filterOption={false}
                // dropdownRender={dropdownRender}
                //do not include filterOption={false} because that makes the already selected tags show in dropdown
                options={options}
            > */}
                        <Input.Search
                            size="large" placeholder="search post title"
                            enterButton allowClear

                        />
                    </AutoComplete>

                </Row>



                <Row type="flex" justify="center" style={{ margin: 20 }}>
                    <Popover
                        content={
                            <Row type="flex" justify="center" style={{ width: 600 }}>
                                <TitleInput
                                    focusOnRender={true}
                                    maxNameLength={this.maxTitleLength}
                                    style={{ width: "100%" }}
                                    handleCreate={this.handleCreate}
                                    clearAfterCreate={true}
                                    visible={createVisible}
                                />
                            </Row>
                        }
                        trigger="click"
                        placement="bottomLeft"
                        visible={createVisible}
                        onVisibleChange={this.handleVisibilityChange}
                        style={{ width: "100%" }}
                    >
                        <Button block type="dashed">Create a New Post</Button>
                    </Popover>
                </Row>



                {
                    treeData ? <div style={{ margin: "12px", padding: "12px" }}>
                        <PostTree
                            {...this.props}
                            ref={this.treeRef}
                            // refname="usergoals"
                            // handleEdit={this.handleEdit}
                            onSelect={this.onSelectTocLeaf}
                            // handleDelete={handleDelete}
                            // firstleveldata={arrayed1sttreedata}
                            addNodeData={this.addNodeData}
                            onSibling={this.onSibling}
                            onParent={this.onParent}
                            treeData={treeData}
                            setTreeData={data => this.setState({ treeData: data })}
                            selected={selectedLeaves}
                            expandedKeys={expandedKeys}
                            loadedKeys={loadedKeys}
                            // onExpand={this.onExpand}
                            setExpandedKeys={keys => this.setState({ expandedKeys: keys })}
                            setLoadedKeys={keys => this.setState({ loadedKeys: keys })}
                        />
                    </div> : null
                }

                {/* </Affix> */}

            </Sider >







            <Content
                width="75%"
                style={{
                    // maxHeight: "none",
                    // margin: '12px 8px 0',
                    // overflow: 'initial',
                    overflow: 'auto',
                    // position: 'fixed',
                    // position: "absolute",
                    maxWidth: "75%",
                    right: 0,
                    marginLeft: '25%',
                    // width: "75%",
                    // left: "25%",
                    // height: "100vh"
                    // float: "right",
                    // position: "relative",
                }}
            // style={{
            //     maxHeight: "none",
            //     overflow: 'auto',
            //     height: '100vh',
            //     position: 'fixed',
            //     left: 0
            // }}
            // breakpoint="md"
            >




                {
                    selectedLeaves.length && Object.keys(titledata).length ?
                        <EditorScreen
                            ref={this.editorRef}
                            {...this.props}
                            itemId={selectedLeaves[0]}
                            itemTitle={titledata[selectedLeaves[0]]}
                            maxTitleLength={this.maxTitleLength}
                            onTitlePressEnter={this.onTitlePressEnter}
                            handleChildCreate={this.handleChildCreate}
                            onDelete={this.handleDelete}
                        />

                        // <Input value={titledata[selectedLeaves[0]]} />

                        // <div></div>
                        :
                        // <p>{selectedLeaf}</p> :
                        <Row type="flex" justify="center" style={{ margin: 20 }}>
                            <TitleInput
                                maxNameLength={this.maxTitleLength}
                                handleCreate={this.handleChildCreate}
                                clearAfterCreate={true}
                            />

                        </Row>
                }


                {/* <Row type="flex" justify="center" style={{ marginTop: 20, marginBottom: 20 }}>
                        <Title>{itemTitle}</Title>
                    </Row> */}


            </Content>







        </Layout >



        </div>


            // </Skeleton>


            // </Fragment >




        );
    }
}




export default App;
