import React from 'react';
import {
    Result, Button
} from 'antd';

import * as ROUTES from "../../constants/routes";

const Page = (props) => <Result
    status="warning"
    title="Payment is cancelled"
    subTitle="You were not charged"
    extra={[
        <Button type="primary" key="console" onClick={() => { props.history.push(ROUTES.PROFILE) }}>
            Go to Your Profile
        </Button>,
        <Button key="home" onClick={() => { props.history.push(ROUTES.HOME) }}>Homepage</Button>,
    ]}
/>;

export default Page;

