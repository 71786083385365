import React from 'react';
// import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';


// import { withFirebase } from '../Firebase';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../Session';

import Page from './page';



function AppCore(props) {

    return (


        <Page {...props} />



    )
}

const App = (props) => {

    return (
        <AuthUserContext.Consumer>
            {authUser => (
                <AppCore {...props} authUser={authUser} />
            )}
        </AuthUserContext.Consumer>

    );
}

const condition = authUser => !!authUser;

export default compose(
    withEmailVerification,
    withAuthorization(condition),
    // withQuiz,
)(App);
