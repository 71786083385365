import React, { Component } from "react";

import { Row, Button, message, Card, Typography } from "antd";


import PasswordChangeForm from "../PasswordChange";


const modalList = ["pwchange"];
// const TabPane = Tabs.TabPane;

const { Title } = Typography;

const { Meta } = Card;



class SettingsBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSignInMethods: [],
      error: null,
      pwchangeVisible: false,
    };
  }

  // showModal = (key) => {
  //   const obj = Object.assign(
  //     ...modalList.map((i) => ({ [`${i}Visible`]: i === key }))
  //   );

  //   this.setState(obj);

  //   console.log("showing the modal");
  // };

  // cancelModal = (key) => {
  //   // console.log(e);
  //   console.log("cancelling modal...");

  //   const stateName = `${key}Visible`;

  //   this.setState({
  //     [stateName]: false,
  //   });
  // };

  componentDidMount() {
    this.fetchSignInMethods();
  }

  fetchSignInMethods = () => {
    this.props.firebase.auth
      .fetchSignInMethodsForEmail(this.props.authUser.email)
      .then((activeSignInMethods) =>
        this.setState({ activeSignInMethods, error: null })
      )
      .catch((error) => {
        this.setState({ error });
        console.log("error fetching signin", error);
        message.error(error.message, 2);
      });
  };


  // onSubmit = () => {
  //   this.setState({
  //     activeSignInMethods: [...this.state.activeSignInMethods, "password"],
  //   });
  // };

  // pwEdit = this.showModal("pwchange");

  render() {

    return (


      < Card style={{ width: "100%", marginTop: 40 }
      }>
        <Meta
          title={<Title level={2}>Password Reset</Title>}
          description="Manage here"
        />

        <PasswordChangeForm
          visible={this.state.pwchangeVisible}
          handleCancel={() => this.setState({ pwchangeVisible: false })}
          authUser={this.props.authUser}
          onSubmit={() => { }}
        />

        <div style={{ minHeight: 300, marginTop: 40 }}>

          <Row type="flex" justify="center" style={{ marginTop: 20 }}>
            Account:&nbsp; <b>{this.props.authUser.email}</b>
          </Row>

          <Row type="flex" justify="center" style={{ marginTop: 20 }}>

            <Button type="primary" onClick={() => this.setState({ pwchangeVisible: true })}>
              Edit My Password
            </Button>

          </Row>
        </div>

      </Card >

    );
  }
}


export default SettingsBase;