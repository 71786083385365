import React from 'react';
import {
    Result, Button, Typography
} from 'antd';

import {
    CloseCircleOutlined
} from "@ant-design/icons";

const { Text, Paragraph } = Typography;

const Page = () => <Result
    status="error"
    title="Submission Failed"
    subTitle="Please check and modify the following information before resubmitting."
    extra={[
        <Button type="primary" key="console">
            Go Console
        </Button>,
        <Button key="buy">Buy Again</Button>,
    ]}
>
    <div className="desc">
        <Paragraph>
            <Text
                strong
                style={{
                    fontSize: 16,
                }}
            >
                The content you submitted has the following error:
            </Text>
        </Paragraph>
        <Paragraph>
            <CloseCircleOutlined className="site-result-demo-error-icon" /> Your account has been
            frozen. <p>Thaw immediately &gt;</p>
        </Paragraph>
        <Paragraph>
            <CloseCircleOutlined className="site-result-demo-error-icon" /> Your account is not yet
            eligible to apply. <p>Apply Unlock &gt;</p>
        </Paragraph>
    </div>
</Result>;

export default Page;
