import React, { useState, Fragment } from "react";

import {
    Row,
    Button
} from "antd";

import moment from 'moment';

import NotMember from "./notyet-member";
import AlreadyMember from "./already-member";
import UserCardNot from "./usercard-notmember";
import UserCard from "./usercard";

import LoginCred from './logincred';


const App = (props) => {

    const [showProfile4NotMember, setShowProfile4NotMember] = useState(false);


    return (
        <div
            style={{
                maxWidth: 1200,
                margin: "auto",
                paddingLeft: 24,
                paddingRight: 24,
            }}
        >

            {props.authUser && props.authUser.current_period_end && props.authUser.current_period_end > moment().unix() && !props.authUser.expired ?
                <Fragment>
                    <UserCard {...props} />
                    <AlreadyMember {...props} />
                    <LoginCred {...props} />
                </Fragment> :

                (showProfile4NotMember ? <Fragment>
                    <UserCardNot {...props} showProfile={setShowProfile4NotMember} />
                    <LoginCred {...props} />
                    <Row type="flex" justify="center" style={{ margin: 40 }}>
                        <Button type="primary" onClick={() => setShowProfile4NotMember(false)} style={{ width: "30%" }}>Back</Button>
                    </Row>
                </Fragment> :
                    <NotMember {...props} showProfile={setShowProfile4NotMember} />)

            }

        </div >
    );
};


export default App;