export const LANDING = "/";
// export const PROFILE = "/profile";
// export const ADMIN = '/admin';
export const HOME = "/home";
export const WRITE = '/write';
export const AUTHOR = "/author";
export const QUIZ = "/quiz";
export const POMO = "/pomodoro";
export const DAY = "/day";
// export const EXTEND = '/extend';
// export const RECOMMEND = '/recommend';
// export const DESCRIPTION = '/description';
// export const LIKES = '/likes';
// export const SETTINGS = "/settings";
export const POSTS = "/posts";
export const PROFILE = "/profile";
export const SETTINGS = "/settings";
export const CRED = "/cred";
export const ACCOUNT = "/account";
export const ABOUT = "/about";
export const PRIVACY = "/privacy";
export const TERMS = "/terms";
export const HELP = "/help";
export const DISCLAIMER = "/disclaimer";
export const CONTACT = "/contact";
export const FAQ = "/faq";
export const PETS = "/pets";
export const SUCCESS = "/success";
export const ERROR = "/error";
export const CANCELLED = "/cancelled";
export const NOTICE = "/notice";
export const PAGE404 = "/404";
export const PAGE403 = "/403";
export const PAYMENT_ERROR = "/paymentError";
