import React from 'react';
import {
    Result, Button
} from 'antd';

import * as ROUTES from "../../constants/routes";

const Page = (props) => <Result
    status="500"
    title="Oops"
    subTitle="Something went wrong."
    extra={<Button key="home" type="primary" onClick={() => { props.history.push(ROUTES.PROFILE) }}>Go back</Button>}
/>;

export default Page;




