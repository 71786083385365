import React, { Component } from 'react';

import {
    message, Button, Result
} from 'antd';

import {
    SmileOutlined
} from '@ant-design/icons';

import moment from 'moment';

import { makeCancelable } from '../Hooks';




class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,
            visible: true,
            dbLoading: false,
            pushkey: null,
            data: {},
            adding: false,
        };

        this.maxShortnameLength = 18;

        this.maxNameLength = 100;


    }

    pendingPromises = [];



    componentWillUnmount = () =>
        this.pendingPromises.map(p => p.cancel());

    appendPendingPromise = promise =>
        this.pendingPromises = [...this.pendingPromises, promise];

    removePendingPromise = promise =>
        this.pendingPromises = this.pendingPromises.filter(p => p !== promise);



    // componentDidMount = () => {

    //     const query = this.props.firebase.db.ref(`${this.props.dbrefname}/${this.props.authUser.uid}`);

    //     const wrappedPromise = makeCancelable(query.once("value"));
    //     this.appendPendingPromise(wrappedPromise);
    //     this.setState({ loading: true });

    //     wrappedPromise.promise
    //         .then(snapshot => snapshot.val())
    //         .then(rawdata => {
    //             if (Object.keys(rawdata).length > 0) {
    //                 this.setState({ data: rawdata, loading: false });
    //                 this.removePendingPromise(wrappedPromise);
    //             }
    //         })
    //         .catch(errorInfo => {
    //             if (!errorInfo.isCanceled) {
    //                 this.setState({ error: errorInfo.error, loading: false });
    //                 this.removePendingPromise(wrappedPromise);
    //             }
    //         });

    // }




    removeFromDb(uid, label, refname) {
        const ref = this.props.firebase[refname];
        return ref(uid).child(label)
            .remove()
            .catch(error => {
                this.setState({ error })
            });
    }





    handleCancel = (itemkey, del) => {
        this.setState({
            visible: false
        });
        if (del) {
            console.log('deleting', itemkey);
            this.handleDelete(itemkey);
        }
    }


    saveValue = (id, newdata) => {
        // oldItem is the goal of interest, for update
        const oldItem = this.state.tableData.filter(d => d.id === id).length > 0 ? this.state.tableData.filter(d => d.id === id)[0] : {};
        this.setState((state, props) => {
            const newItem = { ...oldItem, ...newdata };

            return ({
                tableData: [newItem,
                    ...state.tableData.filter(d => d.id !== id)]
            })
        }
        );
    }


    saveNewListValue = (id, v, whichinput) => {
        // oldItem is the goal of interest, for update
        const oldItem = this.state.tableData.filter(d => d.id === id).length > 0 ? this.state.tableData.filter(d => d.id === id)[0] : {};
        let { [whichinput]: value } = oldItem;
        const newdata = { [whichinput]: Object.assign([], value, v) };

        this.setState((state, props) => {
            const newItem = { ...oldItem, ...newdata };

            return ({
                tableData: [newItem,
                    ...state.tableData.filter(d => d.id !== id)]
            })
        }
        );
    }

    onAddButtonClick = e => {
        e.preventDefault();
        this.setState({ adding: true });

    }

    handleCreate = (name, shortname) => {
        const dbref = this.props.firebase.db.ref(`${this.props.dbrefname}/${this.props.authUser.uid}`);
        const submitTime = Math.round(moment().format('X'));

        const newdata = {
            createTime: submitTime,
            name: name.trim(),
            shortname: shortname.trim(),
        };

        const wrappedPromise = makeCancelable(dbref.push(newdata));

        this.setState({
            dbLoading: true,
        });

        wrappedPromise.promise
            .then(snap => {
                this.setState({
                    pushkey: snap.key,
                    dbLoading: false,
                    tableData: [...this.state.tableData, { ...newdata, id: snap.key }],
                    visible: true,
                });
                this.removePendingPromise(wrappedPromise);
                message.success("Created a new goal");
            })
            .catch(errorInfo => {
                if (!errorInfo.isCanceled) {
                    this.setState({ error: errorInfo.error, dbLoading: false });
                    console.log('push error', errorInfo.error);
                    this.removePendingPromise(wrappedPromise);
                    message.error(errorInfo.message, 2);
                } else {
                    console.log("data fetch is cancelled");
                }
            });

    }

    handleEdit = (itemkey) => {
        this.setState({ dbLoading: true, pushkey: itemkey, visible: true });
    }

    handleDelete = (itemkey) => {
        const dbref = this.props.firebase.db.ref(`${this.props.dbrefname}/${this.props.authUser.uid}/${itemkey}`);
        // const query = ref(this.props.uid);
        const wrappedPromise = makeCancelable(dbref.remove());
        this.appendPendingPromise(wrappedPromise);
        this.setState({
            dbLoading: true,
            tableData: this.state.tableData.filter(d => d.id !== itemkey)
        });

        wrappedPromise.promise
            .then(() => this.removePendingPromise(wrappedPromise))
            .catch(errorInfo => {
                if (!errorInfo.isCanceled) {
                    this.setState({ error: errorInfo.error });
                    this.removePendingPromise(wrappedPromise);
                } else {
                    console.log("book deletion is cancelled");
                }
            });
        this.setState({ dbLoading: false });
    }

    onTabChange = (key) => {
        this.setState({ currentTab: key });
    }

    onSliderChange = value => {
        this.setState({ sliderValue: value });
    };

    onSliderAfterChange = value => {
        if (value <= this.props.min + 0.8 * (this.props.max - this.props.min)) {
            this.setState({ sliderValue: this.props.min })
        } else {
            this.setState({ sliderValue: this.props.max, visible: true });

        }
    }

    onDrawerClose = vi => {
        this.setState({ visible: vi, sliderValue: this.props.min });
    }


    onFinish = (obj) => {
        this.setState({ adding: false, data: { ...this.state.data, ...obj } });
    }

    render() {

        const {
            // tableData,
            data,
        } = this.state;


        const tableData = Object.entries(data).map(([k, t], i) => ({
            id: k,
            submitTime: t.submitTime,
            // createTimeCal: moment(t.createTime * 1000).format('MMMM Do YYYY, h:mm:ss a'),
            // createTimeText: moment(t.createTime * 1000).fromNow(),
            name: t.name,
            type: t.type,
            subtype: t.subtype || null,
            bday: t.bday,
            pawSize: t.pawSize,
            weight: t.weight || null,
        }));


        // const updatedTableData = tableData && tableData.map(t => ({
        //     ...t, ...{
        //         domainLabel: t.domain && save2list[t.domain]['displaylabel'],
        //         domainColor: t.domain && colormapping[save2list[t.domain]['aspect']],
        //         typeLabel: t.type && goaltypes[t.type][0],
        //         typeColor: t.type && goaltypes[t.type][1]
        //     }
        // }));

        // const filteredData = updatedTableData && updatedTableData.filter(d => d['domain'] && d['type']);



        return (


            <div style={{ margin: 20 }}>

                {tableData.length ? <div></div> : <Result
                    icon={<SmileOutlined />}
                    title="You are all caught up!"
                // extra={<Button type="primary">Next</Button>}
                />}

            </div>



        );
    }
}




export default App;

