import React, { useState, useEffect } from "react";

import {
    Form, Input, Button, Row, message
} from 'antd';


import Recaptcha from 'react-recaptcha';

import { recaptcha_public_key } from '../../constants';

import { verifyCaptcha, submitWebform } from '../Functions';

let recaptchaInstance;

const { TextArea } = Input;

window.onloadCallback = () => {
    // console.log('loaded');
};


const App = (props) => {

    const [loading, setLoading] = useState(false);
    const [captcha, setCaptcha] = useState(null);

    const [form] = Form.useForm();

    const verifyCallback = (response) => {


        if (response) {
            verifyCaptcha(response)
                .then(res => {
                    if (res.status === 200) {
                        // this.setState({ captcha: true });
                        // captcha = true;
                        setCaptcha(true);
                    }
                    console.log(res);
                })

        }
    };

    const resetRecaptcha = () => {
        recaptchaInstance.reset();
    };



    const handleSuccess = () => {
        // form.resetFields();
        // console.log("reset fields");
        // resetRecaptcha();
        props.handleCancel();
        setLoading(false);
    };


    const onEmailResponseSuccess = data => {
        message.success("Message sent", 2);
        console.log('success data is', data);
        form.resetFields();
        console.log("reset fields");
        props.setSent(true);
    };


    const onEmailResponseError = data => {
        console.log('error data is', data);
    };


    const onFinish = async (values) => {

        console.log('in on finish', values);

        // const form = this.props.form;
        // const { email, subject, message } = values;

        // const captcha = this.state.captcha;

        // e.preventDefault();

        if (!captcha) {
            message.error("Please check the reCaptcha");
            return;
        }

        setLoading(true);

        submitWebform({ ...values, uid: (props.authUser && props.authUser.uid) || null }, onEmailResponseSuccess, onEmailResponseError)
            .then(() => {
                handleSuccess();
            })
            .catch(error => {
                console.log('update error', error);
                message.error("Message not sent. Please try again.", 2);
                setLoading(false);
            });

        setLoading(true);


    }


    useEffect(() => {

        if (props.visible) {
            // captcha = null;
            resetRecaptcha();

        }
    }, [props.visible]);





    return (

        <Form onFinish={onFinish}
            name="contactForm"
            layout="vertical"
            form={form}
            initialValues={{ email: props.authUser.email, name: props.authUser.username }}
        >
            <Form.Item
                style={{ marginTop: 30 }}
                name="name"
                label="Name"
                rules={[{
                    required: true,
                    message: 'required',
                }]}
                validateTrigger={['onBlur']}
                hasFeedback
            >
                <Input allowClear placeholder="Your name" autoComplete="on" />
            </Form.Item>

            <Form.Item
                style={{ marginTop: 30 }}
                name="email"
                label="Email"
                rules={[{ type: 'email', message: 'Not a valid email address' },
                { required: true, message: 'required' }]}
                validateTrigger={['onBlur']}
                hasFeedback
            >
                <Input allowClear placeholder="Email address" autoComplete="on" />
            </Form.Item>

            <Form.Item
                // {...formItemLayout}
                label="Subject"
                name="subject"
                labelAlign="left"
                rules={[
                    {
                        required: true,
                        message: 'Email subject is required',
                    },
                    {
                        max: 100,
                        message: 'No more than 100 characters.'
                    }
                ]}
            >
                <Input allowClear maxLength={100} />

            </Form.Item>

            <Form.Item
                label="Message"
                name="bodytext"
                labelAlign="left"
                rules={[
                    {
                        required: true,
                        message: 'Message is required',
                    },
                    {
                        max: 300,
                        message: 'No more than 300 characters.'
                    }
                ]}
            >
                <TextArea autoSize={{ minRows: 5 }} showCount allowClear maxLength={300} style={{ minHeight: 100 }} />

            </Form.Item>


            {props.visible ?
                <Row type="flex" justify="center" align="center" style={{ marginTop: 40, marginBottom: 40 }}>
                    <Form.Item
                    >
                        <Recaptcha
                            ref={e => recaptchaInstance = e}
                            sitekey={recaptcha_public_key}
                            // size="compact"
                            render="explicit"
                            verifyCallback={verifyCallback}
                            onloadCallback={window.onloadCallback}
                            theme="dark"
                        // expiredCallback={expiredCallback}
                        />


                    </Form.Item>
                </Row> : <span></span>}

            <Form.Item
            >
                <Button className="login-form-button" type="primary" htmlType="submit" loading={loading} disabled={!captcha}>Send</Button>
            </Form.Item>

        </Form>)
}

export default App;
