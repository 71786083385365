import React from 'react';
import {
    Result, Button
} from 'antd';

import * as ROUTES from "../../constants/routes";

const Page = (props) => <Result
    status="404"
    title="Page not found"
    subTitle="Sorry, the page you requested does not exist."
    extra={<Button key="home" type="primary" onClick={() => { props.history.push(ROUTES.HOME) }}>Go to Homepage</Button>}
/>;

export default Page;


