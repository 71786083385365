import React, { useState } from 'react';


import {
    Button, Row, Modal, Alert
} from 'antd';


import ContactForm from './contact-form';


export const FormDrawer = (props) => {

    return (

        <Modal
            visible={props.visible}
            title={null}
            closable={true}
            maskClosable={false}
            onCancel={props.handleCancel}
            style={{ maxWidth: 400 }}
            footer={null}
        >
            <ContactForm {...props} />

        </Modal>

    );
}



const ContactPage = (props) => {

    const [visible, setVisible] = useState(false);
    const [sent, setSent] = useState(false);

    const onClick = () => {
        setVisible(true);
        setSent(false);
    }

    return (

        <div style={{ maxWidth: 800, margin: "auto", paddingLeft: 24, paddingRight: 24 }}>
            <Row type="flex" justify="center" style={{ marginTop: 20 }}>
                <h1>Contact Us</h1>
            </Row>
            <Row type="flex" style={{ marginTop: 20 }}>
                <p>To send us a message, click the following button.</p>
                <p>We read every message that's sent to us. If a responses is needed we generally respond within two days.</p>
            </Row>
            <Row type="flex" style={{ marginTop: 20 }}>
                <p>You can find the anwer to your question faster if it's in the FAQ. <a href='\faq'>Search the FAQ here.</a> </p>
            </Row>
            <Row type="flex" justify="center" style={{ marginTop: 40 }}>
                <Button style={{ width: "40%" }} type="primary" onClick={onClick}>Write Message</Button>
            </Row>

            <div style={{ marginTop: 40 }}>
                <FormDrawer  {...props}
                    visible={visible}
                    handleCancel={() => setVisible(false)}
                    setSent={setSent} />
            </div>

            <div style={{ marginTop: 40, margin: "auto", maxWidth: 600 }}>
                {sent ?
                    <Alert message="We have received your message. Thank you!" type="success" /> : <span></span>
                }
            </div>
        </div>)
}

export default ContactPage;