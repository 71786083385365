import React from "react";

import {
    Card,
    Row,
    Col,
    Typography,
    // Tag
} from "antd";

import moment from 'moment';

import PortalButton from './portalButton';


const { Title } = Typography;

const { Meta } = Card;


const App = (props) => {

    return <Card style={{ width: "100%", marginTop: 20 }}>
        <Meta
            title={<Title level={2}>Membership</Title>}
            description={`Expiry of Active Membership: ${moment.unix(props.authUser.current_period_end).format("MMMM D, YYYY")}`}
        />


        <Row type="flex" justify="center" gutter={20} style={{ marginTop: 20 }}>
            <Col span={8} >
                <PortalButton options={{ type: "primary", block: true }} children="Manange Membership" {...props} />
            </Col>
        </Row>


    </Card>

};

export default App;