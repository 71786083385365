import { useRef, useEffect, useState } from 'react';
import axios from 'axios';

import { message } from 'antd';
import { queryAllByTitle } from '@testing-library/dom';



export const useAudio = url => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);

    const togglePlay = () => setPlaying(!playing);

    useEffect(() => {
        playing ? audio.play() : audio.pause();
    },
        [playing]
    );

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, []);

    return [playing, togglePlay];
};



export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


export const makeCancelable = (promise) => {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            val => hasCanceled_ ? reject({ isCanceled: true }) : resolve(val),
            error => hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
        );
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        },
    };
};


export const useIsFirstRender = () => {

    const isMountRef = useRef(true);

    useEffect(() => {
        isMountRef.current = false;
    }, []);

    return isMountRef.current;
};


export default function useCancellablePromise(cancelable = makeCancelable) {
    const emptyPromise = Promise.resolve(true);

    // test if the input argument is a cancelable promise generator
    if (cancelable(emptyPromise).cancel === undefined) {
        throw new Error('promise wrapper argument must provide a cancel() function')
    }
    // think of useRef as member variables inside a hook
    // you cannot define promises here as an array because
    // they will get initialized at every render refresh
    const promises = useRef([]);
    // const promises = useRef();

    // useEffect initializes the promises array
    // and cleans up by calling cancel on every stored
    // promise.
    // Empty array as input to useEffect ensures that the hook is
    // called once during mount and the cancel() function called
    // once during unmount
    useEffect(
        () => {
            // initialization, if works... 
            promises.current = promises.current || [];
            // console.log("updated promises:", promises.current);

            // the cleanup
            return function cancel() {
                promises.current.forEach(p => p.cancel());
                promises.current = [];
            };
        }, []
    );


    // cancelablePromise remembers the promises that you
    // have called so far. It returns a wrapped cancelable
    // promise

    function cancellablePromise(p) {
        const cPromise = cancelable(p);
        promises.current.push(cPromise);
        return cPromise.promise;
    }

    return { cancellablePromise };

    // return { promises };
}


//this fetches data
export const useFirebase = (query) => {

    // const query = firebase.db.ref(`${rootname}/${uid}${childpath}`);

    // console.log('query is', query);

    const { cancellablePromise } = useCancellablePromise();

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [refresh, setRefresh] = useState(0);

    // const [url, setUrl] = useState(
    //   'http://hn.algolia.com/api/v1/search?query=redux',
    // );
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        // console.log('fetching...');

        setError(null);
        setLoading(true);

        try {
            const snapshot = await cancellablePromise(query.once("value"));
            // console.log(snapshot.val());
            setData(snapshot.val() || null);
            setLoading(false);
        } catch (err) {
            if (!err.isCanceled) {
                setError(err.error);
                message.error(err.message, 2);
                console.log("error fetching data:", err);
                setLoading(false);
            }
            else {
                console.log("data fetch is cancelled for query", query);
            }
        }


        // setLoading(false);
    };


    useEffect(() => {
        if (query) {
            fetchData();
        }
    }, [refresh]);

    // const doGet = event => {
    //   setUrl(`http://hn.algolia.com/api/v1/search?query=${query}`);
    //   event.preventDefault();
    // };

    return { data, loading, error, refresh, setRefresh };
}


export const useWrite2Db = (ref, method = 'push', original_key = '') => {

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    // const [posting, setPosting] = useState([child, orig_method]);
    const [key, setKey] = useState(original_key);

    // const ref = childname === '' ? firebase[refname](uid) : firebase[refname](uid).child(childname);

    useEffect(() => {

        const postData = async () => {

            if (Object.keys(data).length > 0) {
                setError(null);
                setLoading(true);

                switch (method) {
                    // transaction isn't included as it inccurs multiple visits to server plus needs to use a function
                    case 'set':
                        ref.set(data)
                            .catch(error => {
                                setError(error);
                                console.log('update error', error);
                                message.error(error.message, 2);
                            }).then(() => {
                                message.success("Edits saved");
                            });
                        break;
                    case 'update':
                        ref.update(data)
                            .catch(error => {
                                setError(error);
                                console.log('update error', error);
                                message.error(error.message, 2);
                            }).then(() => {
                                message.success("Edits saved");
                            });
                        break;
                    default:
                        ref.push(data)
                            .catch(error => {
                                setError(error);
                                console.log('update error', error);
                                message.error(error.message, 2);
                            }).then((snap) => {
                                setKey(snap.key);
                                message.success("Edits saved");
                            });
                }



                setLoading(false);
            }
        };
        postData();
    }, [data]);

    return { loading, error, key, setKey, setData };
}



export function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        let isCancelled = false;

        if (!isCancelled) {
            savedCallback.current = callback;
        }

        return () => {
            isCancelled = true;
        };

    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        let isCancelled = false;
        function tick() {
            savedCallback.current();
        }
        if (delay !== null && !isCancelled) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }

        return () => {
            isCancelled = true;
        };

    }, [delay]);
}

// this handles frontend form submit data
export const useFormSubmit = (firebase, rootname, uid, form, childpath = '', momentFields = null) => {

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [submit, setSubmit] = useState(0);
    const [formdata, setFormdata] = useState({});

    const ref = firebase.db.ref(`${rootname}/${uid}${childpath}`);


    // const { uid, refname } = this.props;
    // const { country, province, city, gender, yob } = this.state;
    // const udObject = Object.assign(...fieldlist.map(i => ({ [i]: form.getFieldValue(i) })));

    // e.preventDefault();

    const postData = async () => {

        if (submit) {
            setError(null);
            setLoading(true);

            form.validateFields((err, values) => {

                if (!err) {

                    // let newvalues = {
                    //     ...values};

                    if (momentFields) {
                        momentFields.forEach(
                            x => {
                                const momentvalue = values[x];
                                const strvalue = momentvalue ? momentvalue.format("YYYY-MM-DD") : '';
                                console.log(strvalue);
                                values[x] = strvalue
                            }
                        )
                    }

                    setFormdata(values);

                    console.log(values);

                    ref.update(values)
                        .catch(error => {
                            setError(error);
                            console.log('update error', error);
                            message.error(error.message, 2);
                        });


                    //   this.props.onSubmit();

                } else {
                    message.error('Please check your entries for error', 1);
                    console.log("here's the validation error:", err);
                }
            });


        }
        // try {
        //     const snapshot = await cancellablePromise(query.once("value"));
        //     setData(snapshot.val() || {});
        // } catch (err) {
        //     if (!err.isCanceled) { setError(err.error);}
        //     message.error(err.message, 2);
        // }

        setLoading(false);

    }


    useEffect(() => {
        postData();
    }, [submit]);


    return { formdata, loading, error, setSubmit, submit };
}


//keen to remove this one

export function useModal(init = false) {
    const [visible, setVisible] = useState(init);

    function cancelModal() {
        setVisible(false);
    }

    function showModal() {
        setVisible(true);
    }

    // useEffect(
    //     () => console.log("using Modal")
    // );

    return { visible, cancelModal, showModal, setVisible };
}



// not sure wish to keep this one either


export const useRemoveDb = (firebase, refname, uid, childname = '') => {

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [keyname, setKeyname] = useState(null);

    const ref = childname === '' ? firebase[refname](uid) : firebase[refname](uid).child(childname);

    const deleteData = async () => {

        if (keyname) {
            setError(null);
            setLoading(true);


            ref.child(keyname).remove()
                .catch(error => {
                    setError(error);
                    console.log('update error', error);
                    message.error(error.message, 2);
                });

            setLoading(false);
        }
    }

    useEffect(() => {
        deleteData();
    }, [keyname]);

    return { loading, error, setKeyname };
}



export const useUpdateDb = (firebase, refname, uid, childname = '') => {

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    const ref = childname === '' ? firebase[refname](uid) : firebase[refname](uid).child(childname);

    const postData = async () => {

        if (Object.keys(data).length > 0) {
            setError(null);
            setLoading(true);


            ref.update(data)
                .catch(error => {
                    setError(error);
                    console.log('update error', error);
                    message.error(error.message, 2);
                });

            setLoading(false);
        }
    }

    useEffect(() => {
        postData();
    }, [data]);

    return { loading, error, setData };
}






