import React from "react";

import {
    Table,
    // Tag
} from "antd";

import { level2amount } from "../../constants";

const columns = [
    {
        title: 'Features and Benefits',
        dataIndex: 'name',
        key: 'name',
        align: 'left',
    },
    {
        title: 'No Membership',
        dataIndex: 'no',
        key: 'no',
        align: 'center',
        render: text => {
            return {
                props: {
                    style: { background: "#FFFFE0", opacity: 0.9, color: "black" }
                },
                children: text
            };
        }
    },
    {
        title: 'Standard Membership',
        dataIndex: 'standard',
        key: 'standard',
        align: 'center',
        render: text => {
            return {
                props: {
                    style: { background: "#eeff99", opacity: 0.9, color: "black" }
                },
                children: text
            };
        }
    },
    {
        title: 'Premium Membership',
        key: 'premium',
        dataIndex: 'premium',
        align: 'center',
        render: text => {
            return {
                props: {
                    style: { background: "#b3ffd9", opacity: 0.9, color: "black" }
                },
                children: text
            };
        }

    }
];

const data = [
    {
        key: '1',
        name: 'Price',
        no: 'Free',
        standard: `$${level2amount[1]} per year`,
        premium: `$${level2amount[2]} per year`,
    },
    {
        key: 'features',
        name: 'Upgrades and New Features',
        no: 'All relevant Upgrades',
        standard: 'Automatic access to all upgrades and new features',
        premium: 'Automatic access to all upgrades and new features',
    },
    {
        key: '2',
        name: 'Number of word buttons',
        no: '2',
        standard: '8',
        premium: 'Unlimited',
    },
    {
        key: '3',
        name: 'Suitable pets',
        no: 'Pets with limited language potential',
        standard: 'Pets with some language potential, e.g. having eye contact, showing curiosity',
        premium: 'Pets with indicators of high language potential, e.g. showing much curiosity, well-tuned to the actions, speech or emotions of owners',
    },
    {
        key: '4',
        name: 'Suitable owners',
        no: 'Owners with little to no experience training pets or with this app',
        standard: 'Owners with some experience training pets',
        premium: 'Owners with experience training pets, or have higher than standard knowledge of languages',
    },
    {
        key: 'impact',
        name: 'Community impact',
        no: 'Not contributing financially. We still appreciate your support.',
        standard: 'Thank you for contributing to improve Petspoke and our understanding of pets',
        premium: `Thank you for your contribution. Large number and varieties of interactions are particularly valuable for pets and for human knowledge`,
    },
];


const App = (props) => {


    return <Table columns={columns} dataSource={data} pagination={false} />
}


export default App;