import React, { useState, useEffect } from "react";
// import Markdown from 'react-markdown';


import Faq from './faq';
// import ContactPage from './contact';

import terms from './terms.md';
// import disclaimer from './disclaimer.md';

// import tos from './terms.md';
import about from './about.md';
import privacy from './privacy.md';
import disclaimer from './disclaimer.md';

import Editor, { theme } from "rich-markdown-editor";
import { customDark } from "../Editor/theme.ts";


const DocMD = (props) => {

    const [text, setText] = useState('');

    useEffect(() => {
        fetch(props.path).then((response) => response.text())
            .then((te) => {
                setText(te);
            })
    }, [props.path]);

    return (<>

        <div style={{ maxWidth: 1200, margin: "auto", paddingLeft: 24, paddingRight: 24 }}>
            <article className="markdown-body">
                {/* <Markdown children={text} /> */}
                <Editor
                    autoFocus
                    id='main-editor'

                    theme={customDark}
                    style={{ fontSize: "14px" }}
                    // defaultValue={text}
                    value={text}
                    readOnly={true}

                />
            </article>
        </div>

    </>);

}






const Terms = () => <DocMD path={terms} />;
const About = () => <DocMD path={about} />;
const Privacy = () => <DocMD path={privacy} />;
const Disclaimer = () => <DocMD path={disclaimer} />;

export { Terms, About, Privacy, Disclaimer, Faq };